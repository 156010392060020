/*!
 * Bootstrap v4.0.0-alpha.4 (http://getbootstrap.com)
 * Copyright 2011-2016 The Bootstrap Authors
 * Copyright 2011-2016 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

// Core variables and mixins
@import "../../bower_components/bootstrap/scss/custom";
@import "../../bower_components/bootstrap/scss/variables";
@import "../../bower_components/bootstrap/scss/mixins";

// Reset and dependencies
@import "../../bower_components/bootstrap/scss/normalize";
@import "../../bower_components/bootstrap/scss/print";

// Core CSS
// @import "../../bower_components/bootstrap/scss/reboot";
// @import "../../bower_components/bootstrap/scss/type";
// @import "../../bower_components/bootstrap/scss/images";
// @import "../../bower_components/bootstrap/scss/code";
@import "../../bower_components/bootstrap/scss/grid";
// @import "../../bower_components/bootstrap/scss/tables";
// @import "../../bower_components/bootstrap/scss/forms";
// @import "../../bower_components/bootstrap/scss/buttons";

// Components
// @import "../../bower_components/bootstrap/scss/animation";
// @import "../../bower_components/bootstrap/scss/dropdown";
// @import "../../bower_components/bootstrap/scss/button-group";
// @import "../../bower_components/bootstrap/scss/input-group";
// @import "../../bower_components/bootstrap/scss/custom-forms";
// @import "../../bower_components/bootstrap/scss/nav";
// @import "../../bower_components/bootstrap/scss/navbar";
// @import "../../bower_components/bootstrap/scss/card";
// @import "../../bower_components/bootstrap/scss/breadcrumb";
// @import "../../bower_components/bootstrap/scss/pagination";
// @import "../../bower_components/bootstrap/scss/tags";
// @import "../../bower_components/bootstrap/scss/jumbotron";
// @import "../../bower_components/bootstrap/scss/alert";
// @import "../../bower_components/bootstrap/scss/progress";
// @import "../../bower_components/bootstrap/scss/media";
// @import "../../bower_components/bootstrap/scss/list-group";
// @import "../../bower_components/bootstrap/scss/responsive-embed";
// @import "../../bower_components/bootstrap/scss/close";

// Components w/ JavaScript
// @import "../../bower_components/bootstrap/scss/modal";
// @import "../../bower_components/bootstrap/scss/tooltip";
// @import "../../bower_components/bootstrap/scss/popover";
// @import "../../bower_components/bootstrap/scss/carousel";

// Utility classes
@import "../../bower_components/bootstrap/scss/utilities";
