.ap7__blogAuthor {
  display: flex;
  align-items: center;
  margin-top: 30px;

  &:first-child {
    margin-top: 0;
  }

  .single & {
    display: block;
  }
}

.ap7__blogAuthorAvatar {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 16px;

  .avatar {
    display: block;
  }

  .single & {
    margin-top: 12px;
  }
}

.ap7__blogAuthorTitle {

  .single & {
    font-size: 14px;
    line-height: 22px;
    font-weight: 700;
    margin-top: 7px;
  }
}