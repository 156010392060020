
.table-container {
  overflow-x:hidden;
}

  button.showAll {
    &:focus, &:focus-visible, &:focus-within {
        color: #fff!important;
        border: 1px solid #002b4a!important;
        background: #002b4a !important;
    }
  }

// fold table 
table.fold-table {
  border-collapse: collapse;

  

  .sort-link {
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: right;
    padding-right: 0;
  }

  .sort.desc, .sort, .sort.asc {
    background-image: none;
  }

  .sort.desc, .sort.asc {
    font-weight: 600;
  }

  th:first-child a {
      justify-content: left;
  }

  th {
    font-size: 14px;
  }

  .sort-arrow {
    background-image: url(../images/icon-triangle-inactive-new.svg);
    width: 10px;
    height: 12px;
  }

  .desc .sort-arrow {
    background-image: url(../images/icon-triangle-active.svg);
  }

  .asc .sort-arrow {
    background-image: url(../images/icon-triangle-active.svg);
    transform: rotateX(180deg);
  }

  .desktop {
    display: none;

    @include media-breakpoint-up(lg) {
      display: revert;
    }
  }

  .fold-container {
    padding: 14px 14px 14px 24px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 24px;

    @include media-breakpoint-up(lg) {
      padding: 24px 8px 29px 15px;
      flex-direction: row;
      gap: 0;
    }
  }

  .fold-key {
    @include small-bold-text;
  }

  .fold-value {
    font-size: 18px;
  }

  .fold-date {
    display: flex;
    align-items: end;
    flex-shrink: 0;
    font-size: 14px;
  }

  .key-value {
    display: flex;
    flex-direction: column;
    gap: 2px;

    &.mobile {
      display: revert;
  
      @include media-breakpoint-up(lg) {
        display: none;
      }
    }
  }

  > tbody {
    // view segment
    > tr.view {
      &.open {
        border-bottom: 1px solid #002844;
      }
      td, th {cursor: pointer;}
      td {
        padding: 12px 0 10px 0;

        @include media-breakpoint-up(lg) {
          padding: 19px 0 19px 0;
        }
      }
      .arrow {
          transition: transform 0.1s;
          background: url("../images/icon-arrow.svg");
          display: block;
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          align-self: center;
          padding: 11px 11px 0 0;

          @include media-breakpoint-up(lg) {
            padding: 16px 16px 0 0;
          }
      }
      &.open .arrow {
          transform: rotate(-180deg);
      }
      .title-container {
          display: flex;
          gap: 8px;
          color: #002b4a;

          @include media-breakpoint-up(lg) {
            gap: 12px;
          }
      }

      table {
        border-spacing: 15px 0px;
      }

      border-bottom: 1px solid rgba(0, 40, 68, 0.25);
    }
  
    // fold segment
    > tr.fold {
      display: none;
      background: #F3F2F2;
      &.open { display:table-row; }

      &:last-child {
        td {
          padding-bottom: 10px;
          border-bottom: 1px solid rgba($darkblue, .25);
        }
      }
    }
  }
}
  
  
  
  // fold content 
  .fold-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 18px;
  }