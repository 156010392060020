//CAMPAIGN - REMOVE WHEN DONE

.ap7__headerInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 100%;
  padding: 0 10px;

  background: #002b4a;
  border-bottom: 1px solid white;

  h4 {
    color: white;
    font-weight: 200;
    padding-right: 20px;
  }

  a {
    text-decoration: none;
    border-bottom: 1px solid white;
  }

  .closeHeader {
    display: block;
    width: 20px;
    height: 20px;
    background-image: url("https://www.ap7.se/app/themes/ap7/dist/images/icon-close.png");
    background-repeat: no-repeat;
    background-size: 21px 22px;
    background-position: center center;
    position: absolute;
    right: 15px;
    cursor: pointer;
  }
}

.ap7__header {
  &.hasHeaderInfo {
    top: 80px;
  }

  &--not-top {
    top: 0px !important;
  }
}

//STOP RMEOVING HERE

.ap7__header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px 0 16px;
  background: #e6e2de;
  z-index: 100;

  .home & {
    background: transparent;
  }

  &--pinned {
    position: fixed;
    .home & {
      background: #e8e4e0;
    }
  }
  &--top {
    .home & {
      position: absolute;
      background: transparent;
    }
  }
}

.ap7__headerContainer {
  position: relative;
  display: flex;
  align-items: center;

  @include make-container();
  @include media-breakpoint-up(xl) {
    @include make-container-max-widths();
  }

  &--alignright {
    justify-content: space-between;
    padding-top: 16px;
  }
}

.ap7__headerNav {
  display: flex;
  width: 100%;
  // flex: 1;
  .menu-right {
    //align-items: center;
    margin-left: auto;
    align-items: flex-end;
    display: flex;
  }
  @include media-breakpoint-down(sm) {
    justify-content: flex-end;
    margin-right: 25px;
  }

  .ap7__headerContainer--alignright & {
    flex: initial;
  }
}

.ap7__headerNavList {
  display: flex;
  align-items: baseline;

  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;

  .menu-item {
    padding: 0 10px;

    // @include media-breakpoint-up(lg) {
    //   font-size: 18px;
    //   line-height: 28px;
    // }
    &:not(.in-english):not(.ap7__headerFund) {
      font-size: 16px;
      line-height: 26px;

      @media (min-width: 1100px) {
        font-size: 18px;
        line-height: 28px;
      }

      &.menu-active {
        font-weight: 600;
      }
    }
    &:not(.ap7__headerFund) {
      display: none;
      @include media-breakpoint-up(md) {
        display: block;
      }
    }

    a {
      text-decoration: none;
      color: $darkblue;
      letter-spacing: -0.4px;

      .home .ap7__header--top & {
        color: #fff;
      }
    }

    &.active a {
      font-weight: 700;
    }
  }

  .in-english {
    order: 100;
    // position: absolute;
    // right: 14px;
    // top: 8px;
  }
}

.ap7__headerBrand {
  display: block;
  width: 100%;
  height: auto;
  text-decoration: none;

  &:focus-visible {
    position: relative;
    outline: none;
    &::after {
      content: "";
      display: block;
      border: 1px #fff solid;
      border-color: #fff;
      border-radius: unset;
      position: absolute;
      left: -10px;
      right: -10px;
      top: -6px;
      bottom: -6px;
    }
  }

  @include media-breakpoint-up(md) {
    width: auto;
    margin-right: 4px;
  }

  @include media-breakpoint-down(lg) {
    display: flex;
    align-items: center;
  }

  .post-type-archive-ap7_blog &,
  .tag &,
  .category &,
  .single-ap7_blog & {
    display: flex;
    align-items: center;
  }
}

.ap7__headerFunds {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.ap7__headerFund,
.in-english {
  font-size: 14px;
  // line-height: 140%;
  &--number-0 {
    margin-left: auto;
  }
}

.ap7__headerFundTitle {
  font-size: 11px;
  line-height: 14px;
  color: $blue;

  .home .ap7__header--pinned & {
    color: $darkblue;
  }

  .home &,
  .home .ap7__header--top & {
    color: #fff;
  }

  @include media-breakpoint-up(md) {
    font-size: 14px;
    line-height: 20px;
  }
}

.ap7__headerFundValue {
  font-size: 15px;
  line-height: 23px;
  color: $darkblue;
  font-weight: 700;

  .home .ap7__header--pinned & {
    color: $darkblue;
  }

  .home &,
  .home .ap7__header--top & {
    color: #fff;
  }

  // @include media-breakpoint-up(md) {
  //   font-size: 15px;
  //   line-height: 20px;
  // }
}

.ap7__headerSearch {
  appearance: none;
  background: none;
  border: none;
  padding: 0;
  margin-left: 5px;

  @include media-breakpoint-up(md) {
    margin-left: 8px;
  }

  svg {
    display: block;
    g {
      // stroke: #fff;
    }
    &:last-child {
      display: none;
    }
  }

  &--open {
    svg {
      display: none;
      &:last-child {
        display: block;
      }
    }
  }
}

.ap7__searchOverlay {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  background: $darkblue;
  opacity: 0;
  pointer-events: none;
  backface-visibility: hidden;
  // overflow-y: scroll;
  .no-csspointerevents & {
    display: none;
    opacity: 1;
  }

  &--open & {
    pointer-events: all;
    .no-csspointerevents & {
      display: flex;
    }
  }

  &InnerContainer {
    width: 100%;
  }

  &Content {
    @include make-container();
    @include make-container-max-widths();
  }

  .search-form {
    position: relative;
  }

  .search-submit {
    border: none;
    background: transparent;
    position: absolute;
    background-repeat: no-repeat;
    display: inline-block;
    text-indent: -9999px;
    text-transform: uppercase;
    background-position: center;
    width: 30px;
    background-size: 26px;
    background-image: url(../images/icon-search-white.svg);
    right: 0;
    bottom: 100px;
    margin-bottom: 10px;
    cursor: pointer;
  }

  .failed-missing {
    color: #cf4400;
  }

  .search-field {
    width: 100%;
    appearance: none;
    background: transparent;
    border: none;
    font-size: 24px;
    line-height: 38px;
    outline: 0;
    color: #fff;
    padding: 100px 0;
    @include media-breakpoint-up(md) {
      font-size: 54px;
      line-height: 58px;
    }
  }

  .site-search {
    color: #fff;
    &.failed {
      color: #cf4400;
    }
  }
}

header a:not(.ap7__headerBrand):not(.skip-main),
header button {
  &:focus-visible {
    position: relative;
    outline: none;
    &::after {
      content: "";
      display: block;
      border: 1px #fff solid;
      border-color: inherit;
      border-radius: 19px;
      position: absolute;
      left: -10px;
      right: -10px;
      top: -6px;
      bottom: -6px;
    }
  }
}
header nav.ap7__headerNav a.rect-outlinte {
  &:focus-visible {
    &::after {
      border-radius: 0 !important;
    }
  }
}
body.home .ap7__headerBrand {
  &:focus-visible {
    &::after {
      border: 1px #fff solid;
      border-color: #fff;
    }
  }
}

.ap7__headerBrand {
  &:focus-visible {
    &::after {
      border: 1px #002b4a solid;
      border-color: #002b4a;
    }
  }
}
.ap7__headerBrand_wrapper {
  padding: 20px 0 16px;
  background: #002b4a;
  display: none;
  @media (min-width: 992px) {
    display: block;
  }
  .ap7__headerBrand {
    display: inline-block;
    margin-left: 30px;
    &:focus-visible {
      &::after {
        border: 1px #fff solid;
        border-color: #fff;
      }
    }
    .inner {
      display: flex;
      align-items: center;
    }
    span {
      color: #fff;
      padding-left: 15px;
    }
  }
}
body.blogg .ap7__header,
body.single-ap7_blog .ap7__header,
body.archive.tag .ap7__header,
body.category .ap7__header {
  @media (max-width: 991px) {
    padding: 0;
  }
  .ap7__headerContainer {
    padding: 21px 17px 16px 17px;
  }
  nav.ap7__headerNav {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .ap7__blogInfo {
      margin-left: 0;
    }
  }
}
header {
  a.skip-main {
    //left:-999px;
    position: fixed;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
    z-index: -999;
    left: 0 !important;
    width: 230px;
  }
  a.skip-main:focus,
  a.skip-main:active {
    color: #000;
    background-color: #fff;
    left: 0px;
    top: 0px;
    width: fit-content;
    height: 70px;
    overflow: hidden;
    margin: 0;
    border-radius: 0;
    padding: 20px;
    border: 1px solid black;
    text-align: center;
    font-size: 18px;
    z-index: 999;
    outline: 1px solid #002b4a;
    outline-color: inherit;
    outline-offset: -15px;
  }
}

body.blogg {
  a.skip-main:focus,
  a.skip-main:active {
    // top: 91px;
  }
}

.ap7__headerSearch {
  position: relative;
  top: -2px;
}
body.search.search-results .ap7__header nav.ap7__headerNav .ap7__blogInfo {
  margin-left: 0;
}
body.search.search-results .ap7__header nav.ap7__headerNav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
}
body.search.search-results .ap7__header .ap7__headerContainer {
  padding: 21px 17px 16px;
}
