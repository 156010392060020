html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

* {
  margin: 0;
}

body {
  font-size: 15px;
  line-height: 23px;
  letter-spacing: -0.4px;
  color: $darkblue;

  font-family: "Gotham A", "Gotham B", sans-serif;
  font-style: normal;
  font-weight: 400;

  -webkit-font-smoothing: antialiased;

  @include media-breakpoint-up(md) {
    font-size: 16px;
    line-height: 24px;
  }

  @media (min-width: 1025px) {
    font-size: 20px;
    line-height: 30px;
  }

  &.tag,
  &.category,
  &.page {
    @include media-breakpoint-up(md) {
      font-size: 18px;
      line-height: 25px;
      letter-spacing: 0px;
    }
  }
}

.single-ap7_blog,
.post-type-archive-ap7_blog {
  .wp-post-image {
    margin: 0;
  }
}

//end
.noscroll {
  overflow: hidden;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

a {
  color: inherit;
  // text-decoration: none;

  &:hover {
    p {
      text-decoration: underline;
    }
  }

  .ap7__startblock:not(.ap7__dashboard) &,
  .ap7__blogBlock &,
  .ap7__subNavList &,
  body:not(.single) .hentry &,
  table & {
    text-decoration: none;
  }
}

strong {
  font-weight: 700;
}

.main {
  outline: none;
  ul,
  ol {
    margin-top: 30px;
  }
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  font-weight: 700;
}

.latest-posts-widget a:focus-visible {
  & > h3 {
    outline: 1px solid #002b4a;
    outline-color: inherit;
    outline-offset: 3px;
  }
}

p {
  h1 + &,
  ul + &,
  ol + &,
  figure + &,
  div + &,
  .entry-content-asset + &,
  + p {
    margin-top: 23px;
    @include media-breakpoint-up(md) {
      margin-top: 30px;
    }
  }

  h2 + & {
    margin-top: 15px;

    @include media-breakpoint-up(md) {
      margin-top: 25px;
    }
  }

  h3 + & {
    margin-top: 12px;

    @include media-breakpoint-up(md) {
      margin-top: 14px;
    }
  }
}

h1 {
  font-size: 24px;
  line-height: 32px;

  @include media-breakpoint-up(md) {
    font-size: 42px;
    line-height: 50px;
  }
}

h2,
.h2 {
  font-size: 20px;
  line-height: 28px;

  @include media-breakpoint-up(md) {
    font-size: 28px;
    line-height: 38px;
  }

  p + &,
  ul + &,
  ol + & {
    margin-top: 28px;
    @include media-breakpoint-up(md) {
      margin-top: 60px;
    }
  }
}

h3 {
  font-size: 24px;
  line-height: 38px;

  p + &,
  ul + &,
  ol + & {
    margin-top: 55px;
  }
}

input {
  h2 + & {
    margin-top: 25px;
  }
}

.ap7__h1 {
  font-size: 30px;
  line-height: 42px;

  @include media-breakpoint-up(md) {
    font-size: 52px;
    line-height: 57px;
    letter-spacing: -1.25px;
  }

  &--medium {
    font-weight: 500;
  }
}

.ap7__h2 {
  font-size: 28px;
  line-height: 36px;
  font-weight: 500;

  @include media-breakpoint-up(md) {
    font-size: 52px;
    line-height: 110%;
    letter-spacing: -1.25px;
  }

  &--smaller {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 17px;
    @include media-breakpoint-up(md) {
      font-size: 40px;
      line-height: 110%;
      letter-spacing: -0.5px;
    }
  }

  &--smallest {
    font-size: 20px;
    line-height: 120%;
    font-weight: 900;

    @include media-breakpoint-up(md) {
      font-size: 24px;
      line-height: 120%;
    }
  }

  &--medium {
    font-weight: 500;
  }
}

.ap7__h3 {
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;

  @include media-breakpoint-up(md) {
    font-size: 24px;
    line-height: 120%;
  }

  &--small {
    font-size: 16px;
    line-height: 21px;

    @include media-breakpoint-up(md) {
      font-size: 18px;
      line-height: 24px;
    }
  }

  &--large {
    @include media-breakpoint-up(md) {
      font-size: 32px;
      line-height: 40px;
    }
  }

  &.ap7__dashboardFundTitle {
    // font-size: 22px;
    // line-height: 34px;
    @include small-bold-text;

    // @include media-breakpoint-up(md) {
    //   font-size: 14px;
    //   line-height: normal;
    //   letter-spacing: initial;
    // }
  }
}

.ap7__uc {
  text-transform: uppercase;
}

.ap7__h4 {
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;

  @include media-breakpoint-up(md) {
    font-size: 20px;
    line-height: 38px;
  }
}

.ap7__blogBlockHeading {
  font-size: 22px;
  line-height: 26px;
  font-weight: 300;

  @include media-breakpoint-up(lg) {
    font-size: 28px;
    line-height: 34px;
  }
}

.ap7__text {
  &Smaller {
    @include media-breakpoint-up(md) {
      font-size: 12px;
      line-height: 140%;
    }
  }

  &Small {
    font-size: 11px;
    line-height: 14px;

    @include media-breakpoint-up(md) {
      font-size: 14px;
      line-height: 140%;
    }

    &--bold {
      font-weight: bold;
    }
  }

  &Medium {
    @include media-breakpoint-up(md) {
      font-size: 18px;
      line-height: 140%;
    }
  }

  &large {
    @include media-breakpoint-up(md) {
      font-size: 20px;
      line-height: 140%;
    }
  }

  &Larger {
    font-size: 18px;
    line-height: 140%;
    @include media-breakpoint-up(md) {
      font-size: 24px;
    }
  }

  &--blue {
    color: $blue;
  }
}

blockquote {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-style: italic;
  text-align: center;
  padding: 20px 0 40px;

  @include media-breakpoint-up(md) {
    font-size: 20px;
    line-height: 30px;
    padding: 30px 0 30px;
    margin: 0;
  }
}

cite {
  display: block;
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  font-style: normal;
  margin-top: 26px;

  @include media-breakpoint-up(md) {
    font-size: 16px;
    line-height: 26px;
    margin-top: 24px;
  }
}

.hentry {
  margin-top: 22px;
  padding-bottom: 26px;
  border-bottom: 1px solid rgba($darkblue, 0.35);

  @include media-breakpoint-up(md) {
    margin-top: 34px;
    padding-bottom: 40px;
  }

  .single & {
    margin-top: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
}

// hide recapcha
.grecaptcha-badge {
  visibility: hidden;
}
.recaptcha-terms {
  font-size: 14px;
  line-height: 17px;
}

@media (max-width: 991px) {
  .post-type-archive-ap7_blog .content,
  .single .content {
    padding-top: 0;
  }
}

.ap7__text--special-blue {
  color: $specialBlue;
}

.ap7__blogBlock--videos .newsletter-banner form .failed {
  padding-top: 2px;
  font-weight: normal;
  color: #fff;
  font-size: 0em;
}

.ap7__blogBlock--videos .newsletter-banner form [type="email"] + .failed::before {
  content: "*Ange din e-post";
  font-size: 14px;
}
.ap7__blogBlock--videos .newsletter-banner form .failed.failed-checkbox::before {
  content: "*Godkänn villkoren";
  font-size: 14px;
  text-indent: -20px;
}

.ap7__blogBlock--videos .newsletter-banner form .failed.failed-checkbox {
  text-align: left;
  padding-left: 25px;
}

.ap7__blogBlock--videos .newsletter-banner form .yikes-mailchimp-eu-compliance-label p {
  font-size: 16px;
  line-height: 1.2em;
}

.search .ap7__header {
  padding-top: 0;
}
