@mixin line-height-crop($line-height, $capital-letter: 1) {
  &::before {
    content: "";
    display: block;
    height: 0;
    width: 0;
    margin-top: calc((#{$capital-letter} - #{$line-height}) * 0.5em);
  }
}

@mixin blog-ruler-horizontal {
  background: #0099ff;
  border: none;
  height: 4px;
  margin: 20px 0 20px 0;
}

@mixin post-background($size, $position: center, $repeat: no-repeat) {
  background: {
    position: $position;
    repeat: $repeat;
    size: $size;
  }
}

@mixin aspect-ratio($width, $height) {
  position: relative;
  height: auto;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }

  & > img,
  & > div {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@mixin link-button($padding) {
  font-weight: 600;
  padding: $padding;
  border: 1px solid #002b4a;
  border-radius: 100px;
  display: inline-block;
  // &:focus-visible{
  //   background: #002b4a;
  //   border-color: #fff;
  //   color: white;
  //   outline: none;
  // }
}

@mixin line-clamp($num_lines: 3) {
  /*! autoprefixer: off */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /*! autoprefixer: on */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: $num_lines;
}

@mixin small-bold-text {
  font-size: 14px;
  font-weight: bold;
  line-height: 140%;
  letter-spacing: normal;
}
