
.ap7__mobileNavigation {

}

.ap7__mobileNavigationButton {
  position: fixed;
  width: 76px;
  height: 76px;
  bottom: 34px;
  left: 50%;
  z-index: 200;
  border: solid 2px $blue;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .5);

  transform: translateX(-50%) translateZ(0);
  backface-visibility: hidden;
  transform-style: preserve-3d;
  border-radius: 50%;
  background: $blue;

  @include media-breakpoint-up(lg) {
    display: none;
  }

  &:focus {
    //outline: 0;
  }

  &:before,
  &:after,
  span {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 32px;
    height: 2px;
    transform: translate(-16px, -8px);
    transform-origin: 0 0;
    transition: transform .2s ease-out;
    background: white;
  }

  &:after {
    transform: translate(-16px, 8px);
  }

  span {
    display: block;
    transform: translate(-50%, 0);
    text-indent: -9999px;
    transition: opacity .2s ease-out;
  }

  &--open {
    &:before {
      transform: translate(-11px, -12px) rotate(45deg);
    }
    span {
      opacity: 0;
      // background: pink;
    }
    &:after {
      transform: translate(-12px, 11px) rotate(-45deg);
    }
  }
}

.ap7__mobileNavigationOverlay {
  pointer-events: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 150;
  overflow: hidden;
  -webkit-overflow-scrolling: none;
  
  > div {
    height: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    padding: 74px 20px 150px;

    &::-webkit-scrollbar { 
        display: none; 
    }
  }

  @include media-breakpoint-up(lg) {
    display: none;
  }

  &--is-open {
    pointer-events: auto;
  }
}

.ap7__mobileNavigationList {
  margin: 0;
  padding: 0;
  opacity: 0;
  border-top: 1px solid rgba($blue, .6);

  li {
    display: flex;
    flex-flow: row wrap;

    &.active > a {
      color: $blue;
    }
  }

  > li {
    border-bottom: 1px solid rgba($blue, .6);
  }

  a {
    font-size: 20px;
    line-height: 50px;
    color: white;
    text-decoration: none;
    flex-grow: 1;
  }

  &More {
    display: none;
    appearance: none;
    background: none;
    border: none;
    color: white;
    width: 44px;
    // padding: 0 10px 0 20px;
    background-image: url(../images/icon-expand.svg);
    background-repeat: no-repeat;
    background-position: center center;

    .menu-item-has-children > & {
      // display: inline-block;
      display: block;
    }
  }

  .menu-item-has-children.active,
  .menu-item-has-children.menu-item--open {
    > .sub-menu {
      display: block;
      flex: 1 100%;
    }
    > button {
      background-image: url(../images/icon-expanded.svg);
    }
    > .ap7__mobileNavigationListMore {
      // background: $blue;
    }
  }

  .sub-menu {
    display: none;
    padding: 24px 0 22px 0;
    border-top: 1px solid rgba($blue, .6);

    a {
      font-size: 16px;
      line-height: 36px;
    }

    .sub-menu {
      padding: 4px 0 8px 10px;
      border-top: none;
    }
  }

}