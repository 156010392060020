// Manually adding Boostrap 4 helper classes

.pl-0 {
  padding-left: 0!important;
}

.pl-lg-0 {
  @include media-breakpoint-up(lg) {
    padding-left: 0!important;
  }
}

.pr-lg-0 {
  @include media-breakpoint-up(lg) {
    padding-right: 0!important;
  }
}

.pr-0 {
  padding-right: 0!important;
}

.p-0 {
  padding: 0!important;
}

.no-border {
  border: 0!important;
}