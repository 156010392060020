.ap7__fundFactsContainer,
.ap7__fundHoldingsContainer,
#ap7__fundListingContainer,
#ap7__documentListingContainer {
  margin-top: 36px;
  @include media-breakpoint-up(md) {
    margin-top: 82px;
  }
}

.ap7__fundHoldingsMapContainer {
  margin-top: 36px;

  @include media-breakpoint-up(md) {
    margin-top: 68px;
  }
}

.ap7__fundFactsInnerContainer {
  margin-top: 20px;
  @include media-breakpoint-up(md) {
    margin-top: 42px;
  }
  @include make-row();
}

.ap7__fundFactsCol {
  @include make-col-ready();

  @include media-breakpoint-up(md) {
    @include make-col(6);
  }

  &Content {
    @include media-breakpoint-up(md) {
      border-left: 1px solid rgba($darkblue, .25);
      padding-bottom: 24px;
      padding-left: 28px;
    }
  }
}

.ap7__fundFactsTitle {
  font-size: 14px;
  line-height: 24px;
  margin-top: 14px;
  @include media-breakpoint-up(md) {
    font-size: 16px;
    line-height: 30px;
    margin-top: 26px;
  }

  &:first-child {
    margin-top: 0;
  }
}

.ap7__fundFactsValue {
  font-size: 24px;
  line-height: 32px;
  color: $blue;
  @include media-breakpoint-up(md) {
    font-size: 30px;
    line-height: 42px;
  }

  span {
    opacity: 0.25;
    &.ap7__fundFactsValueActive {
      opacity: 1;
    }
  }

  // strong {
  //   color: $darkblue;
  // }
}

.ap7__fundFactsFooter {
  margin-top: 20px;
}




.ap7__fundPieChartContainer {
  margin-top: 50px;

  path {
    stroke-width: 1px;
    transition: fill 250ms linear;
    transition-delay: 150ms;
  }

  path:hover {
    transition-delay: 0s;
  }

  .legends {
    text {
      font-size: 12px;
      @include media-breakpoint-up(md) {
        font-size: 18px;
      }
    }
  }

  .innerTextValue {
    font-size: 44px;
    fill: $darkblue;
    @include media-breakpoint-up(md) {
      font-size: 94px;
    }
  }

  .innerTextField {
    font-size: 12px;
    fill: $darkblue;
    font-weight: 700;
    @include media-breakpoint-up(md) {
      font-size: 24px;
    }
  }
}



.ap7__table {
  width: 100%;
  border-spacing: 0;
  // font-size: 24px;
  // line-height: 34px;
  text-align: left;

  &Container {
    @include media-breakpoint-down(sm) {
      overflow-x: scroll;
    }
  }

  td.num,
  th.num {
    text-align: right;
    min-width:100px;
    @include media-breakpoint-down(sm) {
      width:auto;
    }
  }

  td {
    padding: 10px;

    &:first-child {
      padding-left: 0;
    }

    a {
      color: $blue;
    }

    &.dl-icon {
      padding-left: 0;
      padding-right: 0;
      text-align: right;
      span {
        display: inline-block;
        width: 36px;
        height: 36px;
        vertical-align: middle;
        background-image: url(../images/icon-download.svg);
      }

      @include media-breakpoint-down(sm) {
        display:none;
      }
    }
  }

  th {
    font-size: 11px;
    line-height: 20px;
    font-weight: 400;
    padding: 0;
    border-top: 1px solid rgba($darkblue, .25);
    border-bottom: 1px solid rgba($darkblue, .25);

    @include media-breakpoint-up(md) {
      font-size: 14px;
      line-height: 28px;
    }

    a {
      display: block;
      padding: 10px 30px 10px 10px;
    }

    &:first-child {
      text-align: left;
      a {
        padding-left: 0;
      }
    }
  }

  tbody {
    tr {
      &:first-child {
        td {
          padding-top: 28px;
        }
      }

      &:last-child {
        td {
          padding-bottom: 28px;
          border-bottom: 1px solid rgba($darkblue, .25);
        }
      }
    }
  }

  .sort {
    background-image: url(../images/icon-triangle-inactive.svg);
    background-repeat: no-repeat;
    background-position: right 10px center;

    &.desc {
      background-image: url(../images/icon-triangle-desc.svg);
    }

    &.asc {
      background-image: url(../images/icon-triangle-asc.svg);
    }
  }

  .no-sort {
    padding: 10px;
  }
}

.ap7__documentListing {
  margin-top: 33px;

  &Filter {
    @extend .ap7__button;
    border-radius: 6px;
    border-color: rgba($darkblue, .25);
    margin: 0 5px;

    &:first-child {
      margin: 0 5px 0 0;
    }

    // appearance: none !important;

    &.active {
      background: $darkblue;
      color: #fff;
    }
  }

  &Search {
    @extend .ap7__searchInput;
    margin-top: 10px;
  }
}

.ap7__fundListing {
  margin-top: 32px;

  @include media-breakpoint-up(md) {
    margin-top: 50px;
  }

  // Search input
  &Search {
    @extend .ap7__searchInput;
  }

  &ShowAll {
    @extend .ap7__button;
    margin-top: 40px;
  }
}

.ap7__fundMapChart {
  position: relative;
  margin-top: 23px;
  @include media-breakpoint-up(md) {
    margin-top: 64px;
  }
}

.ap7__fundMapMarker {
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: black;
  @include media-breakpoint-up(md) {
    width: 19px;
    height: 19px;
  }
}

.ap7__fundMapList {
  line-height: 28px;
  margin-top: 22px;

  @include media-breakpoint-up(md) {
    font-size: 18px;
    line-height: 30px;
    column-count: 2;
    margin-top: 40px;
  }
}

.ap7__fundMapListItem {
  position: relative;
  padding-left: 18px;
  @include media-breakpoint-up(md) {
    padding-left: 23px;
  }
  // cursor: pointer;
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    @include media-breakpoint-up(md) {
      width: 12px;
      height: 12px;
    }
  }
  // &:hover {
  //   font-weight: 700;
  // }
}

$colors-list: #092E48 #3575A0 #2D9EFB #8CCCFC #B6E5FC #9B9B9B #464745;
@each $current-color in $colors-list {
  $i: index($colors-list, $current-color) - 1;
  [data-color="#{$i}"] {
    circle {
      fill: $current-color;
    }

    &.ap7__fundMapMarker,
    &:before {
      background: $current-color;
    }
  }
}