.hero {
  position: relative;
  padding-top: 130px;
  padding-bottom: 30px;
  background: $darkblue;
  color: #fff;

  @include media-breakpoint-up(md) {
    padding-bottom: 80px;
  }
  
  @include media-breakpoint-up(lg) {
    padding-bottom: 100px;
  }
}

.hero__container {
  @include make-container();
  @include make-container-max-widths();

  @include media-breakpoint-up(lg) {
    flex-direction: row;
  }

  padding-left: 0;
  padding-right: 0;

  height: 100%;
  display: flex;
  flex-direction: column;
}

.hero__preamble {
  width: 100%;

  h1 {
    font-size: 30px;
    line-height: 36px;
    font-weight: 500;

    @include media-breakpoint-up(lg) {
      font-size: 52px;
      line-height: 57px;
    }
  }

  p {
    font-size: 18px;
    line-height: 25px;

    @include media-breakpoint-up(lg) {
      font-size: 24px;
      line-height: 34px;
    }
  }

  @include media-breakpoint-up(lg) {
    width: 50%;
  }
}

.hero__chartWrapper {
  height: 100%;
  width: 100%;
  height: calc(100vh - 76px);
  min-height: 400px;
  margin-bottom: -11vh;

  @include media-breakpoint-up(lg) {
    width: 50%;
  }

  max-height: 500px;

  @include media-breakpoint-up(md) {
    height: calc(100vh - 190px);
    margin-bottom: -11vh;
  }
}

// .hero__title {
//   font-size: 20px;
//   line-height: 24px;
//   font-weight: 400;

//   @include media-breakpoint-up(md) {
//     font-size: 24px;
//     line-height: 29px;
//   }
// }

.hero__titleOuterContainer {
  padding: 42px 0 0 0;
  padding-top: calc(11vh + 42px);

  @include media-breakpoint-up(md) {
    padding-top: calc(11vh + 42px);
  }

  @include media-breakpoint-up(lg) {
    padding-top: 11vh;
  }
}

.hero__titleInnerContainer {
  max-width: 500px;
}
.hero__wrap {
  padding: 0 20px;
}
.hero__chartContainer {
  position: relative;
  height: 100%;
  min-height: 300px;
  margin-top: -11vh;

  @include media-breakpoint-up(md) {
    margin-top: -11vh;
  }
}

.hero__chart {
  position: absolute;
  overflow: visible !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.hero__legend {
  position: relative;
  margin-top: 9px;
  padding-left: 37px;
  height: 20px;
  margin-bottom: 15px;

  @include media-breakpoint-up(md) {
    margin-bottom: 0;
  }

  &:before {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    content: "";
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #fff;
  }

  &--blue {
    &:before {
      background: $blue;
    }
  }
}

.hero__legendContainer {
  margin-top: 15px;
  @include media-breakpoint-up(md) {
    margin-top: 18px;
    display: flex;
    margin-right: 50px;
  }
}

.hero__legendText {
  display: block;
  height: 20px;
  line-height: 20px;
  font-size: 14px;

  @include media-breakpoint-up(md) {
    margin-right: 43px;
  }
}

.axis--x path {
  display: none;
}

.line {
  fill: none;
  stroke-width: 3px;
}

.hero__chartGrid {
  fill: url(#hero__chartGradient--mobile);
  shape-rendering: crispEdges;

  @include media-breakpoint-up(lg) {
    fill: url(#hero__chartGradient--desktop);
  }
}

.tick {
  line {
    display: none;
  }

  text {
    font-size: 14px;
    fill: $grey;
  }
}

.overlay {
  fill: none;
  pointer-events: all;
}

.tooltip {
  display: none;
}

.tooltip-rect {
  fill: #001d30;
  // display: none;
}

.tooltip-text {
  // display: none;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  text-anchor: middle;
  // fill: white;
}
.tooltip-date {
  // display: none;
  font-size: 14px;
  line-height: 30px;
  font-weight: 400;
  text-anchor: middle;
  fill: white !important;
}
