.wpcf7-form{
    input, textarea{
        &:not(.wpcf7-submit){
            &:focus{
                outline:rgb(0, 43, 74) 1px solid;
            }
        }
    }
    .wpcf7-not-valid-tip{
        color: #cf4400;
        font-weight: 500;
    }
    .wpcf7-not-valid-tip{
        display: block;
    }
    .wpcf7-response-output{
       padding: 15px 0; 
    }

}
.recaptcha-terms{
    display: none;
}
.wpcf7-response-output{
    color: #cf4400;
    font-weight: 500;
    margin-bottom: 40px;
}


.yikes-easy-mc-form{
    .yikes-mailchimp-eu-compliance-text{
        position: relative;
    }
    .form-field-description{
        font-size: 18px;
        font-style: inherit;
        color: #fff;
        position: absolute;
        left: calc(100% -5%); 
        left:0;
    }
    span.form-field-description{
        position: relative!important;
        margin-top: 0;
        top: 0!important;
    }
}


