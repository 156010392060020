.ap7__blogBlock {
  &--featured {
    h1 { 
      margin-top: 25px;
      
      @include media-breakpoint-up(lg) {
        margin-top: 0;
      }
    }

    p {
      font-size: 15px;
      line-height: 18px;
      margin-top: 10px;
      @include line-clamp;
      @include media-breakpoint-up(lg) {
        margin-top: 20px;
        font-size: 22px;
        line-height: 32px;
      }
    }
    
    .ap7__entryMeta {
      margin-bottom: 20px;
    }

    .col-lg-6 {
      &:first-of-type {
      order: 1;

      @include media-breakpoint-up(lg) {
        order: inherit;
      }
      }
    }

    .image-container {
      @include aspect-ratio(576,325);
      @include post-background(contain);
      background-position: top;

      @include media-breakpoint-up(lg) {
        max-width: 576px;
      }
    }
  }
}