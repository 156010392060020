.ap7__subNavList {
  display: none;
  padding: 0;
  font-size: 18px;
  line-height: 34px;

  @include media-breakpoint-up(lg) {
    display: block;
  }

  .menu-item.active {
    > a {
      font-weight: 700;
    }
  }

  > .menu-item {
    display: none;
    &.active,
    &.current_page_item,
    &.current_page_parent,
    &.current_page_ancestor {
      // display: block;
      > a {
        font-weight: 700;
      }
      &.menu-item-has-children {
        display: block;
        > a {
          font-size: 24px;
          line-height: 34px;
          font-weight: 700;
          color: $specialBlue;
        }
      }
    }
  }

  .menu-item.active,
  .menu-item.current_page_item,
  .menu-item.current_page_parent,
  .menu-item.current_page_ancestor {
    > a {
      font-weight: 700;
    }
  }
  .sub-menu {
    list-style-type: none;
    padding: 0;
    margin-top: 10px;

    > .menu-item-has-children {
      // background: pink; // arrow down

      > .sub-menu {
        display: none;
      }

      &.active,
      &.current_page_item,
      &.menu-item.current_page_parent {
        // background: yellow; // arrow up

        > .sub-menu {
          display: block;
          margin-top: 0;
          padding-left: 10px;
        }
      }
    }
  }
}

.sidebar {
  padding-top: 15px;

  .ap7__archiveIcon {
    display: none;
  }

  .ap7__archiveHeader {
    margin-top: 40px;
  }
}

/* === Blog sidebar === */

.ap7__sideBar {
  margin-bottom: 30px;
  padding-left: 0;
  padding-right: 0;
  position: unset;
  width: 100%;

  &.ap7__sideBar--not-top {
    position: fixed;
    z-index: 200;
    top: 100px;
    right: 0;
  }

  &.ap7__sideBar--unpinned {
    z-index: 1;
  }

  &.docked-bottom {
    position: absolute !important;
    top: auto !important;
    bottom: 0 !important;
    right: 0 !important;
  }

  .ap7__archiveHeader {
    display: flex;
    align-items: center;
    width: 100%;
    background: #002b4a;
    padding: 25px;
    cursor: pointer;
    &:focus-visible {
      outline: 1px solid white;
      outline-offset: -3px;
    }
  }

  .ap7__archiveTitle {
    color: white;
    font-size: 17px;
    line-height: normal;
    margin-right: 10px;
  }

  .ap7__archiveIcon {
    display: inline-block;
  }

  .ap7__archiveListSidebar {
    display: none;

    position: unset;
    width: 100%;
    z-index: 10;
    @include media-breakpoint-up(lg) {
      position: relative;
      position: unset;
      top: 74px;
    }
    ul {
      display: none;
      background-color: #fff;
      li {
        border-bottom: 0.5px solid #bdbdbd;
        a {
          span {
            display: none !important;
          }
        }
      }
    }
  }

  .ap7__archiveHeader--not-top {
    position: absolute;
    top: 0;
    left: 0;
  }
  &.ap7__sideBar--not-top {
    .ap7__archiveListSidebar {
      position: relative;
      left: 0;
      @include media-breakpoint-up(lg) {
        position: relative;
        left: 0;
      }
    }
  }

  .ap7__archiveListYearBlock {
    background: #f7f7f7;
    border-bottom: 0.5px solid #bdbdbd;
    margin-top: 0 !important;

    ul {
      li {
        display: flex;
        align-items: center;
        width: 100%;
        a {
          margin-left: auto;
          margin-right: auto;
          font-size: 18px;
        }
        &:before {
          content: "";
          display: inline-block;
          border-radius: 100px;
          width: 13px;
          height: 13px;
          background-color: #c4c4c4;
          margin-left: 10px;
        }
      }
    }
  }

  .ap7__archiveListYear {
    cursor: pointer;
    padding: 10px 0 10px 10px;
    .ap7__archiveIcon {
      width: 15px;
      height: 15px;
      background: url("../images/icon-archive.svg");
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center;
      display: inline-block;
      margin-left: 5px;
    }
  }
  @include media-breakpoint-up(lg) {
    margin-bottom: 0;
    padding-right: 17px;
    padding-left: 17px;
    order: 2;
  }

  .archive.date & {
    .latest-post-widget {
      display: none;
    }
  }

  .latest-post-widget {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 4px solid #0091f0;

    &:last-of-type {
      border-bottom: 0;
    }
    h3 {
      font-size: 20px;
      line-height: 24px;
    }

    time {
      font-size: 18px;
    }

    p {
      font-size: 17px;
      line-height: 20px;
      @include line-clamp;
    }
  }

  .single & {
    margin-bottom: 30px;
    padding: 0;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
    .latest-posts-widget {
      display: none;

      @include media-breakpoint-up(lg) {
        display: block;
        transition: 1s all ease;
        &.faded {
          display: none;
          transition: 1s all ease;
        }
      }

      .latest-post {
        border-bottom: 4px solid #0091f0;
        padding: 20px 0 22px;

        &:last-child {
          border-bottom: 0;
        }

        &.video-post h3 {
          padding-left: 50px;
          position: relative;
          min-height: 35px;
          margin-top: 12px;

          &::before {
            content: "";
            display: block;
            position: absolute;
            width: 35px;
            height: 35px;
            left: 0;
            top: 0;
            background: #000000;
            border-radius: 35px;
          }
          &::after {
            content: "";
            display: block;
            position: absolute;
            left: 13px;
            top: 10px;
            width: 0;
            height: 0;
            border-top: 8px solid transparent;
            border-bottom: 8px solid transparent;
            border-left: 12px solid #fff;
          }
        }
      }

      h2 {
        font-weight: 300;
        font-size: 28px;
        margin: 25px 0 10px 0;
      }

      h3 {
        font-size: 20px;
        line-height: 24px;
      }
      p {
        font-size: 17px;
        line-height: 20px;
      }
    }
    hr {
      @include blog-ruler-horizontal;
    }
  }
}

body.archive .ap7__sideBar {
  .ap7__archiveHeader--not-top {
    position: absolute;
    top: 0;
    left: -17px !important;
  }
  &.ap7__sideBar--not-top {
    .ap7__archiveListSidebar {
      position: absolute !important;
      left: -17px;
      @include media-breakpoint-up(lg) {
        position: absolute !important;
        left: -17px;
      }
    }
  }
}

//Hide sidebar if blog startpage
.archive.post-type-archive-ap7_blog:not(.date):not(.archive) {
  .ap7__sideBar {
    display: none;
  }
}

.ap7__header--not-top.ap7__header--unpinned
  + .wrap
  aside.ap7__sideBar.ap7__sideBar--not-top.ap7__sideBar--unpinned {
  transform: translateY(-90px);
}
.ap7__header--not-top.ap7__header--pinned
  + .wrap
  aside.ap7__sideBar.ap7__sideBar--not-top.ap7__sideBar--unpinned {
  margin-top: 121px !important;
}
.ap7__sideBar {
  max-width: 480px;
}

@media (min-width: 992px) and (max-width: 1200px) {
  .category .wrap,
  .date .wrap,
  .page:not(.home):not(.page-template-template-lottiepage) .wrap,
  .post-type-archive-ap7_blog .wrap,
  .post-type-archive-ap7_current .wrap,
  .search .wrap,
  .single .wrap,
  .tag .wrap {
    max-width: 100%;
  }
}

.single .ap7__sideBar {
  position: static !important;
  width: 100% !important;
}

@media (min-width: 1200px) {
  .ap7__sideBar.ap7__sideBar--not-top {
    width: calc(1232px / 3 - 34px) !important;
    max-width: 100%;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .ap7__sideBar.ap7__sideBar--not-top {
    width: calc(100vw / 3 - 39px) !important;
    max-width: 100%;
  }
}
@media (max-width: 992px) {
  .ap7__sideBar.ap7__sideBar--not-top {
    position: static !important;
    width: 100% !important;
    max-width: 100% !important;
  }
  .ap7__sideBar {
    max-width: 100% !important;
    width: 100% !important;
  }
}

footer.footer {
  position: relative;
}

// footer.footer::before {
//   content: "";
//   position: absolute;
//   left: 0;
//   top: -100px;
//   width: 100%;
//   height: 100px;
//   background: rgb(255, 255, 255);
//   background: linear-gradient(
//     180deg,
//     rgba(255, 255, 255, 0) 0%,
//     rgba(255, 255, 255, 1) 100%
//   );
// }
