// Grid settings
$container-max-widths: (
  sm: 576px,
  md: 720px,
  lg: 940px,
  xl: 1232px
);
$grid-columns:          12;
$grid-gutter-width:     34px;
$enable-flex:           true;
$main-sm-columns:       8;
$sidebar-sm-columns:    4;

$blog-main-columns:     6;
$blog-sidebar-columns:  3;

// Colors
$blue:                  #0099FF;
$darkblue:              #002B4A;
$grey:                  #F7F7F7;
$specialBlue:           #0091f0;
$oldBlue:				#0099FF;
$orange: #FF6F68;
$lightgreen: #D5E6D4;
$darkgrey: #A3938344;
$lightpink: #FFDBD9;
$lightyellow: #FCF7C8;