$dark: #002c49;
$light: #f7f7f7;
$accent: #0099ff;

.ui-widget {
  font-family: "Gotham A", "Gotham B";
  font-style: normal;
}
#slider-segment {
  .slider-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    > div {
      margin-top: 48px;
      padding: 2rem;
      &:first-child {
        margin-top: 0;
      }

      @include media-breakpoint-up(lg) {
        margin-top: 0;
        padding: 1rem;
      }
    }

    .slider-content-left,
    .slider-content-right {
      // width: 45%;
      // @include single-column {
      //   width: 100%;
      // }
      h3 {
        margin-bottom: 82px;

        @include media-breakpoint-up(md) {
          margin-bottom: 40px;
        }
      }
    }
    .slider-content-left {
      #slider {
        position: relative;
        text-align: left;
        @include media-breakpoint-up(md) {
          margin: 80px 0 0 0;
        }
        #slider-value-age {
          width: 100px;
          text-align: center;
          overflow: visible;
          top: -45px;
          left: -45px;
          position: absolute;
          font-size: 34px;

          &.start-pos-0 {
            left: -6px;
          }

          &.start-pos-1 {
            left: -22px;

            @include media-breakpoint-up(md) {
              left: -35px;
            }
          }

          &.end-pos-0 {
            left: -86px;
          }

          &.end-pos-1 {
            left: -69px;

            @include media-breakpoint-up(md) {
              left: -56px;
            }
          }
        }
        &.ui-corner-all {
          border-radius: 0;
        }
        &.ui-widget.ui-widget-content {
          border: 0;
        }
        &.ui-widget-content {
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0) 40%,
            $light 40%,
            $light 60%,
            rgba(0, 0, 0, 0) 60%
          );
        }
        &.ui-slider-horizontal {
          height: 14px;
        }
        .ui-slider-handle {
          outline: none;
          width: 24px;
          height: 24px;
          top: -6px;
          margin-left: -12px;
          position: absolute;
          z-index: 2;
          cursor: default;
          &:focus {
            outline: 1px solid $light;
            outline-offset: 5px;
          }
        }
        .ui-state-default {
          border: 8px solid $accent;
          border-radius: 50%;
          background-color: $light;
          color: $light;
        }
      }
      .slider-meta {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        .slider-meta-left,
        .slider-meta-right {
          width: 40%;
          span {
            // @include x-rem(font-size, 1.4);
            &.block {
              display: block;
            }
          }
        }
        .slider-meta-right {
          text-align: right;
        }
        .slider-meta-left {
          text-align: left;
        }
      }
      p {
        // @include x-rem(font-size, 1.4);
        margin-top: 45px;
        line-height: 20px;
      }
    }
    .slider-content-right {
      // @include single-column {
      //   margin-top: 50px;
      // }
      #meter-value-slider {
        margin: 82px 0 0 0;
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0) 40%,
          $light 40%,
          $light 60%,
          rgba(0, 0, 0, 0) 60%
        );
        height: 10px;
        position: relative;
        .meter-bar {
          height: 10px;
          background-color: $accent;
        }
        .meter-values {
          top: -45px;
          position: absolute;
          width: 100%;
          display: flex;
          justify-content: space-between;
          font-size: 34px;
        }
      }
      .meter-meta {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        span {
          width: 40%;
          // @include x-rem(font-size, 1.4);
          &:last-child {
            text-align: right;
          }
        }
      }

      .ap7-info {
        margin-top: 40px;

        @include media-breakpoint-up(lg) {
          margin-top: 0;
        }
      }
    }
  }
}

#slider-value-avgift,
#slider-value-risk {
  // transition: color 0.4s;

  &.temp-change {
    color: #0091f0;
    // font-size: 16px;
    font-weight: 700;
  }
}
