.page-template-template-campaign, 
.page-template-template-premiepension, 
.page-template-template-blank {
	color:#fff;
	text-align:left;
	font-family: 'Gotham A', 'Gotham B', 'Helvetica Neue', sans-serif;
	scroll-behavior: smooth;

	-webkit-tap-highlight-color: rgba(0,0,0,0);

	//HIDE ALL WEBSITE ELEMENTS

	header, 
	footer, 
	.sidebar,
	.ap7__mobileNavigationOverlay, 
	.ap7__mobileNavigationList, 
	.ap7__mobileNavigationButton,
	.ap7__searchOverlay,
	.ap7__headerInfo {
		display:none;
	}

	.content,
	.wrap,
	.main {
		padding:0 !important;
		margin:0 !important;
	}

	.angular-wrap {
		position:fixed !important;
		position:absolute;
		top:0;
		right:0;
		bottom:0;
		left:0;

		.main-menu {
			display:block;
			z-index:999;
			position:absolute;
			background:transparent;
			top:17px;
			left:17px;

			a {
				width: 50px;
				height: 50px;
				display:block;
				.ap7-icon {	
					position:absolute;
					display:block;
					width:50px;
					height:50px;
				    transition: all 0s ease-in-out 0.5s;
				    -moz-transition: all 0s ease-in-out 0.5s;
				    -webkit-transition: all 0s ease-in-out 0.5s;

					&.blue { 
						background:url(../../campaign/images/AP7_Logo.svg) no-repeat;
						background-size:100% 100%;
						background-position:center center;
					}

					&.white { 
						background:url(../../campaign/images/AP7_Logo_White.svg) no-repeat;
						background-size:100% 100%;
						background-position:center center;
					}

				}
			}
		}

		.ap7__startblockButton {
			margin-top:12px;
			font-weight:700;
			font-size:80%;
			border-radius:100px;
			-webkit-box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.2);
			-moz-box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.2);
			box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.2);
			&:focus{
				background:  #fff!important;
				border-color: #fff;
				color: #002b4a;
				.exit{
					background:url('../../campaign/images/icon-exit-blue.svg') no-repeat;
				}
				.play {
					background:url('../../campaign/images/icon-play-blue.svg') no-repeat;
					background-size:contain;
				}
			}
			@media (max-width: 320px) {
				font-size:70%;
			}

			@include media-breakpoint-up(lg) {
				padding-bottom:10px;
			}

			&:hover {
				transform:scale(1.05) !important;
				transition: transform 0.2s !important;
			    -webkit-transition: transform 0.2s !important;
			}

			&:active {
				transform:scale(0.95) !important;
				transition: transform 0.2s !important;
			    -webkit-transition: transform 0.2s !important;
			}
			
			 .time-indicator {
			 	display:block;
			 	font-weight:200;
			 	float:right;
			 	margin-left:10px;
			 }

			 .btn-icon {
			 	position:relative;
			 	display:block;
			 	width:20px;
			 	height:20px;
			 	float:right;
			 	margin-left:15px;
			 	top:-2px;

			 	@media (max-width: 320px) {
			 		margin-left:10px;
			 	}

			 	&.exit {
			 		background:url('../../campaign/images/icon-exit.svg') no-repeat;
					background-size:contain;
					transition: all .5s ease-in-out .2s;
			 	}

			 	&.play {
			 		background:url('../../campaign/images/icon-play.svg') no-repeat;
					 background-size:contain;
					transition: all .5s ease-in-out .2s;
			 	}

			 	&.read {
			 		background:url('../../campaign/images/icon-read.svg') no-repeat;
			 		background-size:contain;
			 	}

			 	@include media-breakpoint-up(md) {
			 		width:25px;
			 		height:25px;
			 	}

			 }
		}

		//PAGE TRANSITIONS WITH ANGULAR ANIMATE

		.view.bg--blue {
			background:#012b48;

		}
		.view.bg--ap7-blue {
			background:#0099FF;
		}
		.view.bg--ap7-orange {
			background:#cf4400;
		}

		.view.ng-enter, .view.ng-leave{
	        position: absolute;
	        width: 100%;
	        -webkit-transition: -webkit-transform 0.6s ease-in-out;
	        transition: transform 0.6s ease-in-out;
	    }
	    .view.slide-in-left.ng-enter{
	          transform: translateX(100%);
              -webkit-transform: translateX(100%);
	    }
	    .view.slide-in-left.ng-enter-active{
	          transform: translateX(0);
              -webkit-transform: translateX(0);
	    }
	    .view.slide-out-left.ng-leave{
	          transform: translateX(0);
              -webkit-transform: translateX(0);
	    }
	    .view.slide-out-left.ng-leave-active{
	          transform: translateX(-100%);
              -webkit-transform: translateX(-100%);
	    }

	    .view.slide-in-up.ng-enter{
	          transform: translateY(100%);
              -webkit-transform: translateY(100%);
	    }
	    .view.slide-in-up.ng-enter-active{
	          transform: translateY(0);
              -webkit-transform: translateY(0);
	    }
	    .view.slide-out-up.ng-leave{
	          transform: translateY(0);
              -webkit-transform: translateY(0);
	    }
	    .view.slide-out-up.ng-leave-active{
	          transform: translateY(-100%);
              -webkit-transform: translateY(-100%);
	    }

	    .slideInBlock {
		  transform: translateY(150px);
		  animation: slide 0.8s ease forwards;
		}
		.slideInBlock:nth-child(odd) {
		  animation-duration: 0.6s; /* So they look staggered */
		}

		@keyframes slide {
		  to { transform: translateY(0); }
		}

		//TEXT ANIMATIONS

		@-webkit-keyframes slideIn {
		  0%   { margin-top:100px;}
		  100% { margin-top:0;}
		}
		@-moz-keyframes slideIn {
		  0%   { margin-top:100px;}
		  100% { margin-top:0;}
		}
		@-o-keyframes slideIn {
		  0%   { margin-top:100px;}
		  100% { margin-top:0;}
		}
		@keyframes slideIn {
		  0%   { margin-top:100px;}
		  100% { margin-top:0;}
		}

		@-webkit-keyframes fadeIn {
		  0%   { opacity:0;}
		  30%   { opacity:0;}
		  100% { opacity:1;}
		}
		@-moz-keyframes fadeIn {
		  0%   { opacity:0;}
		  30%   { opacity:0;}
		  100% { opacity:1;}
		}
		@-o-keyframes fadeIn {
		  0%   { opacity:0;}		  
		  30%   { opacity:0;}
		  100% { opacity:1;}
		}
		@keyframes fadeIn{
		  0%   { opacity:0;}
		  30%   { opacity:0;}
		  100% { opacity:1;}
		}

		@-webkit-keyframes slideInUp {
		  0%   { margin-top:100px; opacity:0;}
		  100% { margin-top:0; opacity:1; }
		}
		@-moz-keyframes slideInUp {
		  0%   { margin-top:100px; opacity:0;}
		  100% { margin-top:0; opacity:1; }
		}
		@-o-keyframes slideInUp {
		  0%   { margin-top:100px; opacity:0;}
		  100% { margin-top:0; opacity:1; }
		}
		@keyframes slideInUp {
		  0%   { margin-top:100px; opacity:0;}
		  100% { margin-top:0; opacity:1; }
		}


		@-webkit-keyframes slideInLeft {
		  0%   { margin-left:100px; opacity:0;}
		  100% { margin-left:0; opacity:1; }
		}
		@-moz-keyframes slideInLeft {
		  0%   { margin-right:100px; opacity:0;}
		  100% { margin-left:0; opacity:1; }
		}
		@-o-keyframes slideInLeft {
		  0%   { margin-left:100px; opacity:0;}
		  100% { margin-left:0; opacity:1; }
		}
		@keyframes slideInLeft {
		  0%   { margin-left:100px; opacity:0;}
		  100% { margin-left:0; opacity:1; }
		}

		@-webkit-keyframes fadeInOut {
		  0%   { opacity:0;transform: scale(1);}
		  50%   { opacity:1; transform: scale(1.25);}
		  90% { opacity:0;transform: scale(1.15);}
		}
		@-moz-keyframes fadeInOut {
		  0%   { opacity:0;transform: scale(1);}
		  50%   { opacity:1; transform: scale(1.25);}
		  90% { opacity:0;transform: scale(1.15);}
		}
		@-o-keyframes fadeInOut {
		  0%   { opacity:0;transform: scale(1);}
		  50%   { opacity:1; transform: scale(1.25);}
		  90% { opacity:0;transform: scale(1.15);}
		}
		@keyframes fadeInOut {
		  0%   { opacity:0;transform: scale(1);}
		  50%   { opacity:1; transform: scale(1.25);}
		  90% { opacity:0;transform: scale(1.15);}
		}

		@-webkit-keyframes zoomIn {
		  0%   {transform: scale(1);}
		  50%   {transform: scale(1.25);}
		  100% {transform: scale(1.1);}
		}
		@-moz-keyframes zoomIn {
		  0%   {transform: scale(1);}
		  50%   {transform: scale(1.25);}
		  100% {transform: scale(1.1);}
		}
		@-o-keyframes zoomIn {
		  0%   {transform: scale(1);}
		  50%   {transform: scale(1.25);}
		  100% {transform: scale(1.1);}
		}
		@keyframes zoomIn {
		  0%   {transform: scale(1);}
		  50%   {transform: scale(1.25);}
		  100% {transform: scale(1.1);}
		}


		.contentBlock {
			margin:0 auto;

			&.xtra-padding {
				@include media-breakpoint-up(lg) {
					padding-bottom:20%;
				}
			}

			&.fadeIn {
				  -webkit-animation: fadeIn ease; /* Safari 4+ */
				  -moz-animation:    fadeIn ease; /* Fx 5+ */
				  -o-animation:      fadeIn ease; /* Opera 12+ */
				  animation:         fadeIn ease; /* IE 10+, Fx 29+ */
			}

			&.slideInUp {
				  -webkit-animation: slideInUp ease-in-out; /* Safari 4+ */
				  -moz-animation:    slideInUp ease-in-out; /* Fx 5+ */
				  -o-animation:      slideInUp ease-in-out; /* Opera 12+ */
				  animation:         slideInUp ease-in-out; /* IE 10+, Fx 29+ */
			}

			&.slideInLeft {
				  width:100%;
				  -webkit-animation: slideInLeft ease-in-out; /* Safari 4+ */
				  -moz-animation:    slideInLeft ease-in-out; /* Fx 5+ */
				  -o-animation:      slideInLeft ease-in-out; /* Opera 12+ */
				  animation:         slideInLeft ease-in-out; /* IE 10+, Fx 29+ */
			}

			&.slideInUp, &.slideInLeft {
				&.contentBlock--first {
					  -webkit-animation-duration: 1s; /* Safari 4+ */
					  -moz-animation-duration:    1s; /* Fx 5+ */
					  -o-animation-duration:      1s; /* Opera 12+ */
					  animation-duration:         1s; /* IE 10+, Fx 29+ */

				}
				&.contentBlock--sec {
					  -webkit-animation-duration: 1.2s; /* Safari 4+ */
					  -moz-animation-duration:    1.2s; /* Fx 5+ */
					  -o-animation-duration:      1.2s; /* Opera 12+ */
					  animation-duration:         1.2s; /* IE 10+, Fx 29+ */
				}
				&.contentBlock--third {
					  -webkit-animation-duration: 1.4s; /* Safari 4+ */
					  -moz-animation-duration:    1.4s; /* Fx 5+ */
					  -o-animation-duration:      1.4s; /* Opera 12+ */
					  animation-duration:         1.4s; /* IE 10+, Fx 29+ */
				}
			}

			&.fadeIn{
				&.contentBlock--first {
					  -webkit-animation-duration: 1.2s; /* Safari 4+ */
					  -moz-animation-duration:    1.2s; /* Fx 5+ */
					  -o-animation-duration:      1.2s; /* Opera 12+ */
					  animation-duration:         1.2s; /* IE 10+, Fx 29+ */

				}
				&.contentBlock--sec {
					  -webkit-animation-duration: 1.6s; /* Safari 4+ */
					  -moz-animation-duration:    1.6s; /* Fx 5+ */
					  -o-animation-duration:      1.6s; /* Opera 12+ */
					  animation-duration:         1.6s; /* IE 10+, Fx 29+ */
				}
				&.contentBlock--third {
					  -webkit-animation-duration: 2s; /* Safari 4+ */
					  -moz-animation-duration:    2s; /* Fx 5+ */
					  -o-animation-duration:      2s; /* Opera 12+ */
					  animation-duration:         2s; /* IE 10+, Fx 29+ */
				}

				&.contentBlock--btn {
					  -webkit-animation-duration: 4s; /* Safari 4+ */
					  -moz-animation-duration:    4s; /* Fx 5+ */
					  -o-animation-duration:      4s; /* Opera 12+ */
					  animation-duration:         4s; /* IE 10+, Fx 29+ */
				}
			}
		}

		.btnSection {		
			width:100%;
			padding:20px 0 150px;
			bottom:-100px;
			z-index:2;

			a {
				cursor:pointer;
			}

			&.contentBlock--btn {
				opacity: 0;
			}

			@include media-breakpoint-up(sm) {
				padding:20px 0 50px;
				bottom:-50px;
			}

			&.shareBlock {
				.facebook-icon {
					display:block;
					width:18px;
					height:18px;
					float:right;
					margin-left:14px;
					background:url('../../campaign/images/fb-icon.svg') no-repeat;
					background-size:cover;

					@include media-breakpoint-up(sm) {
						width:20px;
						height:20px;
					}
				}
				.twitter-icon {
					display:block;
					width:22px;
					height:18px;
					float:right;
					margin-left:14px;
					background:url('../../campaign/images/tw-icon.svg') no-repeat;
					background-size:cover;

					@include media-breakpoint-up(sm) {
						width:24px;
						height:20px;
					}
				}
			}

			&.sticky {
				position:sticky !important;
				position:fixed !important;
				position: -webkit-sticky !important;
				padding-bottom: 17px;
				bottom:0;

				@include media-breakpoint-up(md) {
					padding-bottom:10%;
				}

				@include media-breakpoint-up(lg) {
					padding-bottom:5%;
				}

				a {
					transition: all 0.5s ease-in-out;
				    -webkit-transition: all 0.5s ease-in-out;

				    &.delay {
						transition: all 0.5s ease-in-out 0.2s;
				        -webkit-transition: all 0.5s ease-in-out 0.2s;				          
				    }
				}

				&.hidden a {
					transform:scale(0.6);
					opacity:0;
					visibility:hidden;
				}

				&:not(.hidden) a {
					transform:scale(1);
					opacity:1;
					visibility:visible;
				}
			}

			&.bg--blue {
				background: -moz-linear-gradient(top, rgba(1,43,72,0) 0%, rgba(1,43,72,0.04) 1%, rgba(1,43,72,0.08) 2%, rgba(1,43,72,0.35) 15%, rgba(1,43,72,0.54) 33%, rgba(1,43,72,0.65) 45%, rgba(1,43,72,0.76) 57%, rgba(1,43,72,0.87) 78%, rgba(1,43,72,0.9) 93%); /* FF3.6-15 */
				background: -webkit-linear-gradient(top, rgba(1,43,72,0) 0%,rgba(1,43,72,0.04) 1%,rgba(1,43,72,0.08) 2%,rgba(1,43,72,0.35) 15%,rgba(1,43,72,0.54) 33%,rgba(1,43,72,0.65) 45%,rgba(1,43,72,0.76) 57%,rgba(1,43,72,0.87) 78%,rgba(1,43,72,0.9) 93%); /* Chrome10-25,Safari5.1-6 */
				background: linear-gradient(to bottom, rgba(1,43,72,0) 0%,rgba(1,43,72,0.04) 1%,rgba(1,43,72,0.08) 2%,rgba(1,43,72,0.35) 15%,rgba(1,43,72,0.54) 33%,rgba(1,43,72,0.65) 45%,rgba(1,43,72,0.76) 57%,rgba(1,43,72,0.87) 78%,rgba(1,43,72,0.9) 93%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00012b48', endColorstr='#e6012b48',GradientType=0 ); /* IE6-9 */

				a.ap7__startblockButton.ap7__startblockButton--white {
					background:#012b48 !important;
					&:focus{
						background:  #fff!important;
						border-color: #fff;
						color: #002b4a;
						.exit{
							background:url('../../campaign/images/icon-exit-blue.svg') no-repeat;
							transition: all .5s ease-in-out .2s;
						}
						.play {
							background:url('../../campaign/images/icon-play-blue.svg') no-repeat;
							background-size:contain;
							transition: all .5s ease-in-out .2s;
						}
					}
				}
			}

			&.bg--ap7-blue {
				background: -moz-linear-gradient(top, rgba(0,153,255,0) 0%, rgba(0,153,255,0.04) 1%, rgba(0,153,255,0.08) 2%, rgba(0,153,255,0.35) 15%, rgba(0,153,255,0.54) 33%, rgba(0,153,255,0.65) 45%, rgba(0,153,255,0.76) 57%, rgba(0,153,255,0.87) 78%, rgba(0,153,255,0.9) 93%); /* FF3.6-15 */
				background: -webkit-linear-gradient(top, rgba(0,153,255,0) 0%,rgba(0,153,255,0.04) 1%,rgba(0,153,255,0.08) 2%,rgba(0,153,255,0.35) 15%,rgba(0,153,255,0.54) 33%,rgba(0,153,255,0.65) 45%,rgba(0,153,255,0.76) 57%,rgba(0,153,255,0.87) 78%,rgba(0,153,255,0.9) 93%); /* Chrome10-25,Safari5.1-6 */
				background: linear-gradient(to bottom, rgba(0,153,255,0) 0%,rgba(0,153,255,0.04) 1%,rgba(0,153,255,0.08) 2%,rgba(0,153,255,0.35) 15%,rgba(0,153,255,0.54) 33%,rgba(0,153,255,0.65) 45%,rgba(0,153,255,0.76) 57%,rgba(0,153,255,0.87) 78%,rgba(0,153,255,0.9) 93%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000099ff', endColorstr='#e60099ff',GradientType=0 ); /* IE6-9 */
				a.ap7__startblockButton.ap7__startblockButton--white {
					background:#0099FF !important;
					&:focus{
						background:  #fff!important;
						border-color: #fff;
						color: #0099FF;
						.exit{
							background:url('../../campaign/images/icon-exit-light-blue.svg') no-repeat;
						}
						.play {
							background:url('../../campaign/images/icon-play-light-blue.svg') no-repeat;
							background-size:contain;
						}
					}
				}
			}

			&.bg--ap7-orange {
				background: -moz-linear-gradient(top, rgba(236,87,42,0) 0%, rgba(236,87,42,0.01) 1%, rgba(236,87,42,0.08) 6%, rgba(236,87,42,0.35) 22%, rgba(236,87,42,0.54) 33%, rgba(236,87,42,0.65) 45%, rgba(236,87,42,0.76) 57%, rgba(236,87,42,0.87) 78%, rgba(236,87,42,0.9) 93%); /* FF3.6-15 */
				background: -webkit-linear-gradient(top, rgba(236,87,42,0) 0%,rgba(236,87,42,0.01) 1%,rgba(236,87,42,0.08) 6%,rgba(236,87,42,0.35) 22%,rgba(236,87,42,0.54) 33%,rgba(236,87,42,0.65) 45%,rgba(236,87,42,0.76) 57%,rgba(236,87,42,0.87) 78%,rgba(236,87,42,0.9) 93%); /* Chrome10-25,Safari5.1-6 */
				background: linear-gradient(to bottom, rgba(236,87,42,0) 0%,rgba(236,87,42,0.01) 1%,rgba(225,103,51,0.08) 6%,rgba(236,87,42,0.35) 22%,rgba(236,87,42,0.54) 33%,rgba(236,87,42,0.65) 45%,rgba(236,87,42,0.76) 57%,rgba(236,87,42,0.87) 78%,rgba(236,87,42,0.9) 93%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00E14120', endColorstr='#e6E14120',GradientType=0 ); /* IE6-9 */

				a.ap7__startblockButton.ap7__startblockButton--white {
					background:#cf4400 !important;
					&:focus{
						background:  #fff!important;
						border-color: #fff;
						color: #cf4400;
						.exit{
							background:url('../../campaign/images/icon-exit-orange.svg') no-repeat;
						}
						.play {
							background:url('../../campaign/images/icon-play-orange.svg') no-repeat;
							background-size:contain;
						}
						.facebook-icon{
							background:url('../../campaign/images/fb-icon_dark.svg') no-repeat;
						}
						.twitter-icon{
							background:url('../../campaign/images/tw-icon_dark.svg') no-repeat;
						}
					}
				}
			}
		}

	    .videoFrame {
	    	position:relative;
	    	height:100vh;
	    	width:100vw;
	    	overflow:hidden;
	    	background-size: auto 112.5% !important;
	    	z-index:0;

	   		.box-video{
	   			z-index:-1;

	   			video {
	   				position: absolute;
	   				z-index:-2;
	   			}
	   			&.lg-screen, &.sm-screen {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					overflow: hidden;

					video {
					    position: absolute;
					    top: 50%;
					    left: 50%;
					    transform: translateX(-50%) translateY(-50%);
					}
				}
	   			&.sm-screen {
//	   				position:relative;
//	   				width:100% !important;
//	   				height:auto !important;

	   				video {
//	   					position:absolute;
//	   					left:0;
//	   					right:0;
//	   					top:-8vh;
//	   					width: 100% !important;
//	   					height: auto !important;
	   				}
	   			}

			}



			.transOverlay {
			    display: block;
			    width: 100%;
			    height: 100%;
			    position: absolute;
			    top: 0;
			    z-index: 0;
			    opacity:0;

			    &.bg--blue-gradient {
			    	background:#012b48;
			    }

			    &.bg--ap7-blue-gradient {
			    	background:#0099FF;
			    }

			    -webkit-transition: all 1s; /* Safari */
	    		transition: all 1s;

			    &.visible {
			    	opacity:0.5;
			    }
			}

			.playOverlay {
			    display: block;
			    width: 100%;
			    height: 100%;
			    position: absolute;
			    top: 0;
			    z-index: 1;
			    visibility: visible;

			    &.hidden {
			    	visibility:hidden;
			    }
			}


			.replayVideo {
				position:absolute;
				display:block;
				width:100px;
				height:100px;
				margin:0 auto;
				bottom:55%;
				left:0;
				right:0;
				opacity:0;
				visibility:hidden;
				transform: scale(0.9);
				z-index: 1;

				-webkit-transition: all 0.2s ease-in; /* Safari */
    			transition: all 0.2s ease-in;

				&.visible {
					opacity:1;
					visibility:visible;
					transform: scale(1);

					-webkit-transition: all 1s ease-out; /* Safari */
    				transition: all 1s ease-out;
				}

				.replay-icon {

					display:block;
					width:100px;
					height:100px;
					background-size:100%;
					background-position:center center;
					background-repeat:no-repeat;

					&.dark {
						background:url('../../campaign/images/replay-icon-blue.svg') no-repeat;
						background-position:center center;
					}

					&.light {
						background:url('../../campaign/images/replay-icon-ap7blue.svg') no-repeat;
						background-position:center center;
					}
				}
			}

			.playVideo {
				position:absolute;
				display:block;
				width:100px;
				height:100px;
				margin:0 auto;
				bottom:55%;
				left:0;
				right:0;
				opacity:0;
				transform: scale(0.9);
				-webkit-transition: all 0.2s ease-in 0s; /* Safari */
	   			transition: all 0.2s ease-in 0s;


				&.visible {
					opacity:1;
					transform: scale(1);
					-webkit-transition: all 0.2s ease-out 0.5s; /* Safari */
    				transition: all 0.2s ease-out 0.5s;
				}

				.play-icon {
					display:block;
					width:100px;
					height:100px;

					&.dark {
						background:url('../../campaign/images/play-icon-blue.svg') no-repeat;
						background-position:center center;
					}

					&.light {
						background:url('../../campaign/images/play-icon-ap7blue.svg') no-repeat;
						background-position:center center;
					}
				}
			}

			.pauseVideo {
				position:absolute;
				display:block;
				width:100px;
				height:100px;
				margin:0 auto;
				bottom:55%;
				left:0;
				right:0;
				opacity:0;

				&.visible {
					-webkit-animation: fadeInOut 0.5s ease-in-out; /* Safari 4+ */
					-moz-animation:    fadeInOut 0.5s ease-in-out; /* Fx 5+ */
					-o-animation:      fadeInOut 0.5s ease-in-out; /* Opera 12+ */
					animation:         fadeInOut 0.5s ease-in-out; /* IE 10+, Fx 29+ */
				}

				.pause-icon {
					display:block;
					width:100px;
					height:100px;

					&.dark {
						background:url('../../campaign/images/pause-icon-blue.svg') no-repeat;
						background-position:center center;
					}

					&.light {
						background:url('../../campaign/images/pause-icon-ap7blue.svg') no-repeat;
						background-position:center center;
					}

				}
			}

			.soundButton {
				position:absolute;
				right:10px;
				top:0;
				opacity:1;
				z-index:9999;
				cursor:pointer;
			    -webkit-box-shadow: none;
				-moz-box-shadow: none;
				box-shadow: none;


				-webkit-transition: all 0.2s; /* Safari */
    			transition: all 0.2s;

				&.hidden {
					opacity:0;
				}

				#btnText {
					display:block;
					float:left;
					width:120px;
					height:18px;
					opacity:1;
					-webkit-transition: width 0.05s; /* Safari */
    				transition: width 0.05s;
    				
    				white-space: nowrap;
					overflow: hidden;

					&.hide {
						opacity:0;
						width:0px;
					}
					@include media-breakpoint-up(md) {
						width:130px;
					}
					@include media-breakpoint-up(lg) {
						width:170px;
						height:20px;
					}
				}

				.volume-icon {
					display:block;
					width:20px;
					height:18px;
					float:left;

					background:url('../../campaign/images/sound-on-icon.svg');
					background-size:100%;
					background-repeat:no-repeat;
					background-position:center;

					@include media-breakpoint-up(md) {
						width:25px;
						height:23px;
					}

					&.on {
						width:20px;
						height:18px;
						background:url('../../campaign/images/sound-icon.svg');
						background-size:100%;
						background-repeat:no-repeat;
						background-position:center;
					}
						
				}
			}

			.videoBlock {
				position:absolute;
				bottom:-100%;
				height:100%;
				width:100%;
				-webkit-transition: all 1s; /* Safari */
	    		transition: all 1s;

	    		@include media-breakpoint-up(md) {
			    	height:100%;
			    	bottom:-100%;
			    }

				&.visible {
					height:100%;
					bottom:-45%;
					z-index:2;
				}

				.videoBlock--inner {
					margin: 0 auto;
					position:relative;

					.btnBlock {
						position:absolute;
						opacity: 0;
						text-align:left;
						bottom:0;
						margin-bottom:-48%;
						transition: all 1s;
						-webkit-transition: all 1s;

						&.visible {
							opacity:1;
						}

						@media (max-width: 320px) {
							margin-bottom:-40%;
						}

						@media all and (orientation: landscape) { 
							margin-bottom:-15%;
						}
						@include media-breakpoint-up(md) {
							margin-bottom:-50%;
						}
						@include media-breakpoint-up(lg) {
							margin-bottom:-40%;
						}
					}

				}
			}
		}
	}


	//PARTIAL TEMPLATE STYLING

	.campaignTemplate {
		height: calc(100vh);
		padding:80px 0 25px;

		@include media-breakpoint-up(md) {
			padding:100px 0 25px;
		}
		.columnWidth {
			width:calc(100%);
			margin:0 auto;
			overflow:hidden;
		}
		.bottom-gradient {
				position:sticky;
				position:fixed;
				position:-webkit-sticky;
				bottom:0;
				height:0%;
				width:100%;
				z-index:1;

				&.hidden {
					display:none;
				}

				transition: all 1s ease-in-out;
				-webkit-transition: all 1s ease-in-out;

				&.scrolling {
					bottom:0%;
				}

				@include media-breakpoint-up(lg) {
					height:0%;
					
					&.scrolling {
						bottom:0%;
					}
				}

				
					&.bg--blue {
						background: -moz-linear-gradient(top, rgba(1,43,72,0) 0%, rgba(1,43,72,0.12) 4%, rgba(1,43,72,0.33) 15%, rgba(1,43,72,1) 57%, rgba(1,43,72,1) 100%); /* FF3.6-15 */
						background: -webkit-linear-gradient(top, rgba(1,43,72,0) 0%,rgba(1,43,72,0.12) 4%,rgba(1,43,72,0.33) 15%,rgba(1,43,72,1) 57%,rgba(1,43,72,1) 100%); /* Chrome10-25,Safari5.1-6 */
						background: linear-gradient(to bottom, rgba(1,43,72,0) 0%,rgba(1,43,72,0.12) 4%,rgba(1,43,72,0.33) 15%,rgba(1,43,72,1) 57%,rgba(1,43,72,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
						filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00012b48', endColorstr='#012b48',GradientType=0 ); /* IE6-9 */

						a.ap7__startblockButton.ap7__startblockButton--white {
							background:#012b48 !important;
						}
					}

					&.bg--ap7-blue {
						background: -moz-linear-gradient(top, rgba(0,153,255,0) 0%, rgba(0,153,255,0.12) 4%, rgba(0,153,255,0.33) 15%, rgba(0,153,255,1) 57%, rgba(0,153,255,1) 100%); /* FF3.6-15 */
						background: -webkit-linear-gradient(top, rgba(0,153,255,0) 0%,rgba(0,153,255,0.12) 4%,rgba(0,153,255,0.33) 15%,rgba(0,153,255,1) 57%,rgba(0,153,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
						background: linear-gradient(to bottom, rgba(0,153,255,0) 0%,rgba(0,153,255,0.12) 4%,rgba(0,153,255,0.33) 15%,rgba(0,153,255,1) 57%,rgba(0,153,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
						filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000099ff', endColorstr='#0099ff',GradientType=0 ); /* IE6-9 */
						
						a.ap7__startblockButton.ap7__startblockButton--white {
							background:#0099FF !important;
						}
					}

					&.bg--ap7-orange {
						background: -moz-linear-gradient(top, rgba(232,85,50,0) 0%, rgba(232,85,50,0.12) 4%, rgba(232,85,50,0.33) 15%, rgba(232,85,50,1) 57%, rgba(232,85,50,1) 100%); /* FF3.6-15 */
						background: -webkit-linear-gradient(top, rgba(232,85,50,0) 0%,rgba(232,85,50,0.12) 4%,rgba(232,85,50,0.33) 15%,rgba(232,85,50,1) 57%,rgba(232,85,50,1) 100%); /* Chrome10-25,Safari5.1-6 */
						background: linear-gradient(to bottom, rgba(232,85,50,0) 0%,rgba(232,85,50,0.12) 4%,rgba(232,85,50,0.33) 15%,rgba(232,85,50,1) 57%,rgba(232,85,50,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
						filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00e85532', endColorstr='#cf4400',GradientType=0 ); /* IE6-9 */


						a.ap7__startblockButton.ap7__startblockButton--white {
								background:#cf4400 !important;
						}
					}
			}

		.campaignTemplate--inner {
			margin:0 auto;
			height:100%;

			h1 {
				padding-bottom:20px;

				@include media-breakpoint-up(lg) {
					padding-bottom:40px;
					padding-top:5%;
				}

				@media (max-width: 320px) {
					font-size:24px;
					line-height:30px;
					padding-bottom:10px;
				}

				.bold {
					font-weight:700 !important;
				}
			}

			p {
				&.bold {
					font-weight:700 !important;
				}
			}

			&.scrollContent {
				position:relative;
				height:calc(100% + 130px);
				width:calc(100% + 40px);
				overflow-y:scroll !important;
				overflow-x:hidden !important;
				-webkit-overflow-scrolling: touch;
				-ms-overflow-style: none;
				padding-top:150px;
				padding-bottom:50px;
				margin-top:-100px;
				margin-bottom:130px;
				padding-right:40px;

				&.fundOptionTemplate {
					padding-bottom:70%;
					@include media-breakpoint-up(md) {
						padding-bottom:40%;
					}
					@include media-breakpoint-up(lg) {
						padding-bottom:2%;
					}
				}

				&.landingPageTemplate,&.leavingPageTemplate {
					padding-bottom:70%;
					@include media-breakpoint-up(md) {
						padding-bottom:40%;
					}
					@include media-breakpoint-up(lg) {
						padding-bottom:2%;
					}
				}

				@include media-breakpoint-up(lg) {
					padding-top:100px;
					padding-top:20vh;
					margin-top:-105px;

						.avkastning,.risk,.nedtrappning,.avgift,.triangeln{
							width:75%;
							margin:0 auto;

							svg {
								min-height:348px;
								height:100%;
								width:auto;
							}
						}
				}

				&::-webkit-scrollbar { 
				    display: none !important; 
				}

				.gsapScroll {
				  opacity: 0;
				  &.gsapFadeInFixed {
				  		margin-bottom:100px;
				  }
				  &.visible {
						opacity:1 !important;
					}
				}

				.visible {
					opacity:1 !important;
				}
				.gsapFadeInFixed:not(.gsapScroll) {
				  		margin-bottom:0;
				  }

				.avkastning {
					opacity:1 !important; 
				}

				.statGraphScroll {
				  opacity: 1;
				}

				.section {
					position:relative;
					padding:30px 0 0;
					
				 	p {
				    	margin-top:20px;
				  	}
				}
			}

		}

		&.bg--white {
			background:#fff;
		}
		&.bg--blue {
			background:#012b48;

		}
		&.bg--medium-blue {
			background:#005893;
		}
		&.bg--light-blue {
			background:#3498db;
		}
		&.bg--ap7-blue {
			background:#0099FF;
		}

		&.bg--blue-gradient {
			background: -moz-linear-gradient(top, rgba(1,43,72,0) 0%, rgba(1,43,72,0.5) 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(top, rgba(1,43,72,0) 0%,rgba(1,43,72,0.5) 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to bottom, rgba(1,43,72,0) 0%,rgba(1,43,72,0.5) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00012b48', endColorstr='#80012b48',GradientType=0 ); /* IE6-9 */
			color:#fff;

			a.ap7__startblockButton.ap7__startblockButton--white {
				background:#012b48 !important;
			}
		}

		&.bg--ap7-blue-gradient {
			background: -moz-linear-gradient(top, rgba(0,153,255,0) 0%, rgba(0,153,255,0.5) 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(top, rgba(0,153,255,0) 0%,rgba(0,153,255,0.5) 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to bottom, rgba(0,153,255,0) 0%,rgba(0,153,255,0.5) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000099ff', endColorstr='#800099ff',GradientType=0 ); /* IE6-9 */
			color:#fff;

			a.ap7__startblockButton.ap7__startblockButton--white {
				background:#0099FF !important;
			}
		}

		.buttonBlock {
			position:absolute;
			bottom:15%;

			@include media-breakpoint-up(lg) {
				bottom:5%;
			}
		}

		.inputBlock {
			position:relative;
			width:100%;
			padding-top:20px;
			padding-bottom:20px;

			.fundList {
				display:block;
				height:100%;
				list-style:none;
				padding:0;
				margin:0 0 100px;

				li {
					.item {
						display:block;
						width:100%;
						text-align:left;
						font-weight:300;
						font-size:18px;

						.fundName {
							color: rgba(255, 255, 255, 0.7);
							display:inline-block;
							max-width:90%;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;

							&:focus, &:active {
								color: rgba(255, 255, 255, 1) !important;

							}
						}

						.highlighted {
							color: rgb(255, 255, 255);
							font-weight:500 !important;
						}

						&:after {
							content:'';
							display:inline-block;
							float: right;
							width:11px;
							height:20px;
							background:url('../../campaign/images/right-icon.png');
							background-size:100%;
							background-repeat:no-repeat;
							margin-top:3px;
						}
					}
				}
			}
			.icon {
				height:100px;
				width:100px;
				font-size:40px;
				display:block;
				margin:20px auto 50px;

				img {
					width:100%;
					height:auto;
					object-fit:cover;
				}
			}

			input {
				width:100%;
				border: 1px solid #fff;
				border-radius:0;
				padding:10px;
				margin-bottom:10px;
				margin-top:20px;
				background:transparent;
				opacity:0.7;
				color:#fff;

				&:focus {
					opacity:1;

						::-webkit-input-placeholder { /* Chrome/Opera/Safari */
						  opacity:0;
						}
						::-moz-placeholder { /* Firefox 19+ */
						  opacity:0;
						}
						:-ms-input-placeholder { /* IE 10+ */
						  opacity:0;
						}
						:-moz-placeholder { /* Firefox 18- */
						  opacity:0;
						}
				}

				&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
					  color:#fff;
				}
				&::-moz-placeholder { /* Firefox 19+ */
					  color:#fff;
				}
				&:-ms-input-placeholder { /* IE 10+ */
					  color:#fff;
				}
				&:-moz-placeholder { /* Firefox 18- */
					  color:#fff;
				}
			}

		}

		p {
			margin:15px 0;
		}

		a {
			font-weight:bold;
			display:inline-block;
			width:auto;

			&.text-link {
				padding-bottom: 0;
				line-height: 1.2;
				font-weight: normal;
				text-decoration: none;
				border-bottom: 1px solid white;
			}

			&.item {
				text-decoration:none;
				margin:0 auto;
				padding:8px 0;

				@include media-breakpoint-up(lg) {
					padding:4px 0;
				}
			}
		}
		.filter-text {
			font-size:90%;
			font-style:italic;
		}

		&.leavingBlock {
			.campaignTemplate--inner {

				.share-image {
					padding-top:20px;
					#img {
					    -webkit-box-shadow: 0px 2px 10px 0px rgba(120,5,5,0.5);
						-moz-box-shadow: 0px 2px 10px 0px rgba(120,5,5,0.5);
						box-shadow: 0px 2px 10px 0px rgba(120,5,5,0.5);

						@include media-breakpoint-up(sm) {
							width:80%;
							margin:0 auto;
						}	
					}
				}

				.textBlock {
					height:100%;
					position:absolute;
					left:0;
					right:0;

					.topText {
						max-width:50%;
						padding-top:12%;
						margin:0 auto;
						font-size:4vw;
						line-height:3.5vh;
						text-align:center;

						@include media-breakpoint-up(sm) {
						   	font-size:24px;
						   	line-height:36px;
						}
						@include media-breakpoint-up(md) {
							padding-top:10%;
						}
						@include media-breakpoint-up(lg) {
							padding-top:2%;
						   	max-width:48%;
						}
						.time {
							text-align:center;
							margin:-10px auto 0;
							padding-top:0px;
							h2 {
								font-size:6vw;
								line-height:4.5vh !important;

								@include media-breakpoint-up(sm) {
							    	font-size:34px;
							    	line-height:46px !important;
							    }
							}
						}
					}

					.bottomText {
						position:absolute;
						bottom:17%;

						@include media-breakpoint-up(sm) {
							bottom:7%;
						}
						@include media-breakpoint-up(md) {
							bottom:20%;
						}
						@include media-breakpoint-up(lg) {
							bottom:5%;
						}

						.smallText {
							font-size:3.7vw;
							line-height:3.4vh;

							@include media-breakpoint-up(sm) {
							   	font-size:18px;
							   	line-height:30px;
							}

							a {
								text-decoration:underline;
								font-weight:400 !important;
							}

						}
						.shareBlock {
							.facebook-icon {
							 	display:block;
							 	width:18px;
							 	height:18px;
							 	float:right;
							 	margin-left:14px;
							 	background:url('../../campaign/images/fb-icon.svg') no-repeat;
							 	background-size:cover;
							 	@include media-breakpoint-up(sm) {
									width:20px;
								 	height:20px;
								}
							}
						}
					}

				}


				#svg-container {
				    text-align: center;
				    position: absolute;
				    top: 15%;
				    left:0;
				    right:0;
				    margin:0 auto;
				    width: 100%;
				    transform: translateY(-50%);

				    svg {
				        display: inline-block;
				        width: 130%;
				        height: 130%;
				        margin-left:-15%;
				        margin-top:15%;

						@include media-breakpoint-up(sm) {
				        	width:100%;
				        	height:100%;
				        	margin-left:0%;
				        	margin-top:20%;
						}
						@include media-breakpoint-up(md) {
				        	width:130%;
				        	height:130%;
				        	margin-left:-15%;
				        	margin-top:15%;
						}
				        @include media-breakpoint-up(lg) {
				        	width:100%;
				        	height:100%;
				        	margin-left:0%;
				        	margin-top:8%;
						}

				        .one-line {
				            opacity: 0;
				        }
				    }
				}

			}

		}

	}

}
body.alltdubehoverveta{
	.cc-banner{
		display: none;
	}
}