.ap7__blogBlock {
  &--more-posts {
    .ap7__blogBlockPosts {
      margin-bottom: 48px;
    }

    .ap7__blogBlockInnerContainer {
      border-bottom: 0;
    }
    .ap7__blogBlockPostsContent {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #C4C4C4;
    padding-bottom: 20px;

      h3 {
        font-size: 20px;
        line-height: 24px;
        height: 48px;
        margin: 5px 0 10px 0;
        @include media-breakpoint-down(md) {
          height: auto; 
        }
      }
      p {

        font-size: 14px;
        line-height: 17px;
        @include line-clamp;

        @include media-breakpoint-up(lg) {
          margin-top: 0;
          font-size: 17px;
          max-height: 60px;
          line-height: 20px;
        }

      }

      time {
        font-size: 14px;
      }
    }

    .col-lg-6 {
      margin-bottom: 20px;
    }

    .post-image {
      @include post-background(cover);
      @include aspect-ratio(180, 180);
      flex-basis: 30%;

      @include media-breakpoint-down(sm) {
        max-height: 120px;
      }
    }

    a {
      flex-basis: 68%;
    }

    a.archive-link-button {

      @include link-button(20px 40px); 
      &:focus-visible{
        background: #002b4a;
        border-color: #fff;
        color: white;
        outline: none;     
      }
    }
  }
}