.ap7__entryMeta {
  display: block;
  font-size: 14px;
  line-height: 20px;
  margin-top: 12px;

  .ap7__date {
    color: $darkblue;
  }

  .ap7__tax {
    font-weight: normal;
  }

  @include media-breakpoint-up(md) {
    font-size: 16px;
    line-height: 22px;
    margin-top: 20px;
  }
}

.entry-content {
  margin-top: 24px;
}

.wp-post-image {
  margin-top: 16px;

  @include media-breakpoint-up(md) {
    margin-top: 30px;
  }

  &.attachment-featured-small {
    margin-top: 0;
    margin-bottom: 16px;
    max-width: 50%;
    height: auto;

    @include media-breakpoint-up(md) {
      margin-bottom: 30px;
    }
  }
}

.wp-caption-text {
  font-size: 13px;
  line-height: 19px;
  margin-top: 6px;

  @include media-breakpoint-up(md) {
    font-size: 16px;
    line-height: 24px;
    margin-top: 14px;
  }
}
