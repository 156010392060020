
#cookieBar {
    display:block;
    width:100%;
    top:-20px;
    z-index: -9999;
    position: relative;
}

.cc-window {
    font-family:"Gotham A", "Gotham B", sans-serif;
    margin:0;
    right:0;
    left:0;
    max-width:100%;
    position: relative;
    padding-right:45px !important;
    padding-left:17px !important;
    padding-top:12px !important;
    z-index: 149;
}


.cc-window.cc-top {
    top:0;
    position: relative;
}

.cc-window.cc-bottom {
    bottom: 0;
    position: fixed;
}

@keyframes lineInserted {
    from {
    height: 0;
    }
    to {
    height: 70px; /* your line height here */
    }
}

.open {
    min-height: 20px;
    max-height: 70px;
    margin-top: 0;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
    overflow: auto;
}

#cookieBar.open, .cc-window.open {
    min-height:70px;
    transition: all 0.3s ease;
}

.closed {
    margin-top:-130px;
    height:128px;
}

.cc-banner .cc-btn:last-child{
    min-width:auto;
}


.cc-message {
    padding-top:0 !important;
    color:#fff;
    margin:0 auto !important;
    width:100%;
    font-size: 14px;
    line-height: 1.5;
}

.cc-message p {
    display: inline;
}

.cc-link {
    display:inline-block;
    border-bottom:1px solid #fff;
    text-decoration:none;
    font-weight: normal;
    opacity:1;
    text-align:center;
    padding: 0 !important;
    font-size: 14px;
    line-height: 1.5;
}

@media all and (max-width:320px){
    .cc-link, .cc-message {
        line-height: 1.2;
    }
    .cc-btn {
        right:10px;
        top:10px;
    }
}

@media all and (min-width:544px){
    .cc-message {
        max-width: 576px !important;
    }
}

@media all and (min-width:768px){
    .cc-window {
        max-width:100% !important;
        padding-right: 100px !important;
        padding-top:17px !important;
    }
    .cc-message {
        max-width: 720px !important;
        padding-top:0;
        padding-left: 24px;
    }
    .cc-link {
        margin-top:0;
    }
    .cc-message, .cc-link {
        font-size: 16px;
    }
    .cc-btn {
        transform:scale(1) !important;
        top: 23px;
        right: 17px;
    }
}	

@media all and (min-width:992px){
    .cc-window {
        padding-right:17px !important;
    }
    .cc-message {
        max-width: 940px !important;
        padding-top:0;
        margin-top:0;
        padding-left: 0px;
    }
    .cc-color-override-137790986 .cc-btn {
        top:23px;
    }
}

@media all and (min-width:1025px){
    .cc-message {

    }
}
@media all and (min-width:1220px){
    .cc-message {
        max-width: 1232px !important;
    }
}
