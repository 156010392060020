.related-posts {
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    @include media-breakpoint-up(lg) {
      flex-wrap: wrap;
      flex-direction: row;
      margin-top: 84px;
    }
  
    h2 {
      flex-basis: 100%;
      font-size: 22px;
      @include media-breakpoint-up(lg) {
        font-size: 28px;
      }
      font-weight: 300;
      line-height: 36px;
      margin-bottom: 30px;
    }
    h3 {
      font-size: 18px;
      line-height: 20px;
      word-wrap: break-word;
    }

    .post {
      margin-bottom: 20px;
      text-decoration: none;
      @include media-breakpoint-up(lg) {
        flex-basis: 23%;
        max-width: 23%;
        margin-bottom: 0;
        margin-right: 20px;
        &:last-child {
          margin-right: 0;
        }
      }
    }

    hr {
      @include blog-ruler-horizontal;
      margin: 10px 0;

      @include media-breakpoint-up(lg) {
        margin: 20px 0;
      }
    }

    time {
      font-size: 14px;
      line-height: 17px;
      margin-top: 10px;
      display: block;
    }

    .image-container {
      @include post-background(cover);
      @include aspect-ratio(375,154);

      @include media-breakpoint-up(lg) {
        @include aspect-ratio(213,203);
      }
    }
}