/**
 * Startpage template
 */

.ap7__startblock {
  padding: 34px 0 44px;
  background: #fff;
  border-color: $darkblue;

  @include media-breakpoint-up(md) {
    padding: 70px 0;
  }

  &.ap7__dashboard {
    padding: 0;

    .ap7__startblockInnerContainer > div {
      flex-direction: column-reverse;
      gap: 0;

      @include media-breakpoint-up(md) {
        gap: 17px;
      }

      @include media-breakpoint-up(lg) {
        gap: 0;
        flex-direction: row;
      }
    }
  }

  &--grey {
    background: $grey;
  }

  &--blue {
    background: $blue;
  }

  &--orange {
    background: $orange;
  }

  &--lightgreen {
    background: $lightgreen;
  }

  &--lightpink {
    background: $lightpink;
  }

  &--lightyellow {
    background: $lightyellow;
  }

  &--darkblue {
    background: $darkblue;
    color: #fff;
    border-color: #fff;
  }

  &--color {
    padding: 80px 0;
  }

  &--bgimage {
    // position: relative;
    // height: 0;
    color: #fff;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    .ap7__startblockInnerContainer {
      // padding-top: 106px;
    }

    @include media-breakpoint-up(md) {
      // padding-top: 200px;

      .ap7__startblockInnerContainer {
        // padding-top: 146px;
      }
    }

    @include media-breakpoint-up(lg) {
      // padding-top: 108px;
    }
  }

  &--blog {
    p {
      margin-bottom: 20px;
      font-size: 22px;
      line-height: 32px;
    }
    hr {
      @include blog-ruler-horizontal;
    }

    a.blog-link-button {
      @include link-button(10px 20px);
    }

    .col-lg-6 {
      order: 2;
      &.blog-image {
        order: 1;
        margin-bottom: 20px;
      }

      @include media-breakpoint-up(lg) {
        order: inherit;

        &.blog-image {
          margin-bottom: 0;
          order: inherit;
        }
      }
    }
  }

  &--video {
    padding: 140px 0 44px 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    color: #fff;

    @include media-breakpoint-up(md) {
      padding: 56.25% 0 0 0;
      height: 0;
      position: relative;
    }

    &169 {
      padding: 56.25% 0 0 0;
      height: 0;
      position: relative;
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &Color {
    display: flex;
    flex-direction: column-reverse;
    @include media-breakpoint-up(md) {
      flex-direction: row;
      align-items: flex-start;
    }

    .image {
      margin-bottom: 7px;
      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }
    }
  }

  &QuickNav {
    background-color: $darkblue;
    color: $grey;

    details {
      display: block;

      summary {
        cursor: pointer;
        display: block;
        width: 100%;
        border-bottom: 1px solid $grey;

        font-size: 20px;
        line-height: 120%;
        letter-spacing: -0.5px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;

        &::after {
          margin-left: auto;
          font-weight: normal;
          display: inline-block;
          content: "+";
        }

        &::-webkit-details-marker {
          display:none;
        }
      }

      ul {
        list-style: none;
        padding-left: 0;
        margin-top: 0;

        li {
          display: block;
          padding: 12px 0;
          border-bottom: 1px solid $blue;

          a {
            display: flex;
            justify-content: space-between;
            font-size: 20px;
            line-height: 140%;

            &::after {
              content: "→";
              display: inline-block;
            }
          }
        }
      }
      &[open] summary {
        border: none;
        &::after {
          content: "–";
        }
      }
    }

    .content {
      display: none;
    }

    @include media-breakpoint-up(md) {
      padding: 64px 0;

      details {
        display: none;
      }
      .content {
        display: block;

        .buttons {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-wrap: wrap;

          list-style-type: none;
          padding: 0;
          margin-top: 23px;

          li > a {
            margin: 0;
            margin-right: 18px;
            margin-bottom: 23px;
          }
        }
      }
    }
  }
}

.ap7__startblockOuterContainer {
  // position: absolute;
  // width: 100%;
  // bottom: 120px;
  .ap7__startblock--video & {
    @include media-breakpoint-up(md) {
      position: absolute;
      width: 100%;
      bottom: 120px;
    }
  }
}

.ap7__startblockInnerContainer {
  @include make-container();
  @include make-container-max-widths();

  > div {
    @include make-row();
  }
}

.ap7__startblockPosts {
  margin-top: 30px;

  @media (min-width: 1025px) {
    margin-top: 60px;
  }
  & .ap7__postCurrent:not(:first-child) &Content {
    margin-top: 38px;
    @include media-breakpoint-up(md) {
      margin-top: 0;
    }
  }
  &Content {
    .image {
      width: 100%;
      height: auto;
      object-fit: cover;
      margin-bottom: 7px;
    }

    @media (min-width: 1100px) {
      padding-top: 0;
      margin-top: 0;
      border-top: none;
      font-size: 18px;
      line-height: 28px;
    }
  }

  &BlogContent {
    padding-top: 22px;
    margin-top: 32px;
    border-top: 1px solid rgba($darkblue, 0.25);

    @include media-breakpoint-up(lg) {
      padding-top: 0;
      margin-top: 0;
      border-top: none;
      font-size: 18px;
      line-height: 28px;
    }
  }

  > div:first-child {
    .ap7__startblockPostsBlogContent {
      padding-top: 0;
      margin-top: 0;
      border-top: none;
    }
  }

  .ap7__postCurrent {
    @include make-col-ready();

    @media (min-width: 1100px) {
      @include make-col(4);
    }
  }

  [data-filter] {
    font-weight: 400;
  }

  [data-group] {
    display: none;
    @include make-col-ready();
    @media (min-width: 1100px) {
      @include make-col(12);
    }
  }

  [data-group="all"] {
    display: block;
  }

  .ap7__filterList {
    font-size: 18px;
    line-height: 28px;
    margin: 0;
    padding: 0;
    list-style-type: none;

    @media (min-width: 1025px) {
      font-size: inherit;
      line-height: 34px;
    }

    &Container {
      @include make-col-ready();

      @media (min-width: 1100px) {
        @include make-col(3);
      }
    }
  }

  .ap7__activeFilter {
    color: $darkblue;
    font-weight: 700;
  }
}

.ap7__startblockCampaignContent {
  margin-top: 16px;
  @include media-breakpoint-up(md) {
    margin-top: 24px;
  }
}

.ap7__startblockVideoContent {
  margin-top: 14px;

  @include media-breakpoint-up(md) {
    margin-top: 22px;
  }
}

.ap7__startblockPlayButton {
  display: inline-block;
  width: 50px;
  height: 50px;
  margin-bottom: 16px;
  border: none;
  background-color: transparent;
  background-image: url(../images/icon-play.svg);
  background-size: 100% 100%;

  @include media-breakpoint-up(md) {
    width: 77px;
    height: 77px;
    margin-bottom: 18px;
  }
}

.ap7__startblockPressContent {
  margin-top: 16px;

  @include media-breakpoint-up(md) {
    margin-top: 24px;
  }
}

.ap7__startblockButton {
  &:focus {
    background: #002b4a;
    border-color: #002b4a;
    color: white;
  }
  &.ap7__startblockButton--white {
    &:focus {
      background: #fff;
      border-color: #fff;
      color: #002b4a;
    }
  }

  &--inherit {
    color: inherit;
    background: inherit;
    border-color: inherit;
  }

  @extend .ap7__button;
  margin-top: 40px;

  @include media-breakpoint-up(md) {
    margin-top: 60px;
  }

  &--white {
    color: #fff;
    border-color: #fff;
  }
  &--marginsm {
    margin-top: 34px;
    @include media-breakpoint-up(md) {
      margin-top: 45px;
    }
  }
}

.wpcf7 {
  margin-top: 23px;
  form p {
    input,
    textarea {
      max-width: 100%;
    }
  }
  @include media-breakpoint-up(md) {
    margin-top: 30px;
  }
}

.screen-reader-response {
  position: absolute;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  width: 1px;
  margin: 0;
  padding: 0;
  border: 0;
}

.page__share-this {
  margin-top: 50px;
  display: flex;
  align-items: flex-start;

  a {
    display: inline-block;
    margin-left: 10px;
  }

  svg {
    display: block;
  }
}

.page-nav {
  margin-top: 30px;

  a:first-child {
    float: left;
  }

  a:first-child {
    float: right;
  }
}

.pages-a-z {
  min-height: 500px;

  ul.letters {
    padding: 0;
    margin-bottom: 80px;
    li.page-letter {
      display: inline;
      padding: 0 2px;
      cursor: pointer;
      text-decoration: underline;
    }
  }
  ul.list-pages {
    padding-left: 0;
    list-style: none;
  }
}

.ap7__lottiePage {
  padding-top: 84px;

  h1 {
    font-weight: 500;
  }

  .lottie-heading {
    font-size: 30px;
    @include media-breakpoint-up(md) {
      font-size: 40px;
    }
  }

  .lottie-arrow-animation {
    position: fixed;
    width: 100%;
    bottom: 0;
    height: 100px;
  }

  .animation-description {
    margin-top: 56px;

    @include media-breakpoint-up(md) {
      margin-top: 34px;
    }

    p+p {
      margin-top: 10px;
    }
  }

  .intro-content {
    font-size: 18px;
    margin-top: 17px;
    line-height: 25px;

    @include media-breakpoint-up(md) {
      font-size: 24px;
      line-height: 34px;
      margin-top: 23px;
    }

    p+p {
      margin-top: 6px;

      @include media-breakpoint-up(md) {
        margin-top: 10px;
      }
    }
  }
  .switch-container {
    margin-top: 25px;
    display: flex;
    gap: 9px;
    align-items: center;
  }

  .static-arrow {
    margin-top: 100px;
    display: flex;
    justify-content: center;
  }

  .lottie-ending-wrapper {
    padding-top: 80px;
    padding-bottom: 100px;

    @include media-breakpoint-up(md) {
      padding-bottom: 170px;
    }
  }

  .lottie-ending {
    @include make-container();
    @include make-container-max-widths();
    font-size: 18px;

    h2 {
      font-weight: 500;
    }
  }

  .lottie-color-block {
    @include make-container();
    @include make-container-max-widths();
    display: flex;
    flex-direction: column;
    gap: 84px;
    padding-top: 84px;
    padding-bottom: 100px;

    @include media-breakpoint-up(md) {
      gap: 195px;
      padding-top: 90px;
      padding-bottom: 280px;
    }
  }

  .lottie-image-block {
    display: flex;
    flex-direction: column;
    gap: 40px;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      gap: 0;
    }

    .lottie-image {
      height: 100%;
    }

    h2+p, h3+p, h4+p {
      margin-top: 17px;
    }

    h3 {
      font-size: 18px;
      line-height: 120%;

      @include media-breakpoint-up(md) {
        font-size: 20px;
      }
    }

    p+p {
      margin-top: 10px;

      @include media-breakpoint-up(md) {
        margin-top: 17px;
      }
    }
  }

  .lottie-intro-wrapper {
    padding-top: 17px;

    @include media-breakpoint-up(md) {
      padding-top: 90px;
    }
  }

  //Set default lottie off to reduce image flickering for senitive people
  .lottie-row {
    display: none;
  }

  .static-container {
    display: block;
  }

  // If browser is set to no-preference allow lottie player to show
  @media (prefers-reduced-motion: no-preference) {
    .lottie-row{
      display: block;
    }
    .static-container{
      display: none;
    }
  }


  &.toggled-animation {
    .lottie-arrow-animation {
        display: none;
        @include media-breakpoint-up(lg) {
          display: block;
      }
    }
    .lottie-row.lottie-row, .animation-on {
      display: block;
    }
    .static-container.static-container, .static-arrow, .animation-off {
      display: none;
    }
  }

  &.toggled-animation-off {
    .lottie-row.lottie-row, .lottie-arrow-animation, .animation-on {
      display: none;
    }
    .static-container.static-container, .animation-off {
      display: block;
    }

    .static-arrow {
      display: flex;
    }
  }

  .lottie-row {
    height: 1500vh;
    width: 100%;

    .colored-background {
      width: 100%;
      margin-top: -100vh;
      margin-bottom: 100vh;

      &.desktop {
        display: none;
        @include media-breakpoint-up(md) {
          display: block;
        }
      }

      &.mobile {
        display: block;
        @include media-breakpoint-up(md) {
          display: none;
        }
      }
    }

    .lottie-animation {
      width: 100%;
      height: 100vh;
      position: sticky;
      top: 0;

      &.desktop {
        visibility: hidden;
        height: 0;
        @include media-breakpoint-up(md) {
          visibility: visible;
          height: 100vh;
        }
      }

      &.mobile {
        visibility: visible;
        @include media-breakpoint-up(md) {
          visibility: hidden;
          height: 0;
        }
      }
    }
  }

  .lottie-intro {
    min-height: 100vh;
    @include make-container();
    @include make-container-max-widths();
  }

    /* The switch - the box around the slider */
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: transparent;
    -webkit-transition: .4s;
    transition: .4s;
    outline: 1px solid #002b4a;
    outline-offset: -1px;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: #002b4a;
    -webkit-transition: .4s;
    transition: .4s;
  }

  input:checked + .slider:before {
    background-color: white;
  }

  input:checked + .slider {
    background-color: #002b4a;
  }

  input:focus + .slider {
    box-shadow: 0 0 3px #002b4a;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  h2, h3, h4 {
    font-size: 28px;
    font-weight: 500;

    @include media-breakpoint-up(md) {
      font-size: 40px;
      font-weight: 500;
    }
  }
}
.ap7__categoryPage{
  padding: 0 17px;
}

.page-template-template-tankonomi{
  .main{
    width: 100%;
  }
}

.ap7__categoryPage{
  padding: 0 17px;
}

.page-template-template-listning-med-puffar{
  .main{
    width: 100%;
  }
}

.page-template-template-jubileum{
  .main{
    width: 100%;
  }
}

.ap7__categoryPage, .ap7__tankPage {
  .top-block{
    flex-direction: column-reverse;
    @include media-breakpoint-up(md) {
     flex-direction: row;
    }
  }

  a,
  a * {
    text-decoration: none;
  }
  a:hover * {
    text-decoration: underline;
  }
  img {
    object-fit: contain;
    object-position: top;
    width: 100%;
    margin-bottom: 7px;
    @include media-breakpoint-up(md) {
      margin-bottom: 16px;
    }
  }
  h1 + p {
    margin-top: 17px;
  }
  h2 {
    margin-bottom: 17px;
    word-break: break-word;
    @include media-breakpoint-up(md) {
      margin-bottom: 23px;
    }
  }
  h2 + p {
    margin-top: unset;
  }
  .row > div {
    margin-bottom: 38px;
    @include media-breakpoint-up(md) {
      margin-bottom: 80px;
    }

    &.hero-image {
      position: relative;
    }
  }

  &HeroText {
    max-width: 685px;
    margin-right: auto;
  }

  .hero-image {
    display: none;
    @include media-breakpoint-up(md) {
      display: block;
    }
  }
  > div:first-of-type {
    margin-bottom: 40px;
    @include media-breakpoint-up(md) {
      margin-bottom: unset;
    }
  }
}
