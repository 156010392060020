.ap7__blogBlock {
  &.tank-page{
    a.archive-link-button {
      margin-top: 48px;

      @include link-button(10px 20px);
      &:focus-visible{
        background: #002b4a;
        border-color: #fff;
        color: white;
        outline: none;
      }
    }
  }

  &--latest {
    .ap7__blogBlockPostsContent {

      h3 {
        font-size: 20px;
        line-height: 24px;
        @include media-breakpoint-up(lg) {
          height: 64px;
          margin-bottom: 25px;
        }
      }
      p {
        display: none;

        @include media-breakpoint-up(lg) {
          display: -webkit-box;
          font-size: 17px;
          line-height: 20px;
          height: 61px;
          @include line-clamp;
        }
      }

      .image-container {
        @include post-background(cover);
        @include aspect-ratio(268,152);
        margin-bottom: 20px;

        @include media-breakpoint-up(lg) {
          max-width: 268px;
        }
      }
    }
  }
}