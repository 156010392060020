.dropdown {
  letter-spacing: 0;
  > :first-child {
    margin-top: 30px;

    @include media-breakpoint-up(md) {
      margin-top: 60px;
    }
  }

  h2 {
    margin-top: 70px;
    margin-bottom: 15px;
    font-weight: 500;
    line-height: 24px;

    @include media-breakpoint-up(md) {
      margin-top: 68px;
      line-height: 110%;
    }
  }
}

.dropdown-item {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 4px;
  min-height: 62px;
  button {
    all: unset;
    flex-grow: 1;
    width: 100%;
    display: flex;
    padding: 10px 14px 10px 14px;
    cursor: pointer;
    min-height: 42px;
    align-items: center;

    @include media-breakpoint-up(md) {
      padding: 19px 48px 18px 14px;
    }

    &:focus-visible {
      outline-style: auto;
    }

    .arrow {
      transition: transform 0.1s;
      background: url("../images/icon-arrow.svg");
      //width: 15px;
      display: block;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      align-self: center;
      padding: 15px 15px 0 0;
      margin-right: 14px;
    }

    h3 {
      font-size: 15px;
      font-weight: 325;
      line-height: 140%;

      @include media-breakpoint-up(md) {
        font-size: 18px;
      }
    }
  }

  .dropdown-content {
    display: none;
    flex-grow: 1;
    width: 100%;
    padding: 20px 14px 0 20px;
    line-height: 140%;

    @include media-breakpoint-up(md) {
      padding: 38px 90px 0 43px;
    }
  }

  &.open-dropdown {
    margin-bottom: 20px;

    @include media-breakpoint-up(md) {
      margin-bottom: 44px;
    }
    .dropdown-content {
      display: block;
    }

    .arrow {
      transform: scaleY(-1);
    }
  }

  //For browsers that don't support focus-visible it can cause issues if we group these selectors.
  //(Specifically older Safari versions)
  &:focus-visible {
    margin-bottom: 20px;

    @include media-breakpoint-up(md) {
      margin-bottom: 44px;
    }
    .dropdown-content {
      display: block;
    }

    .arrow {
      transform: scaleY(-1);
    }
  }
}
