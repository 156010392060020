.footer {
  background: $darkgrey;
  padding: 35px 0 70px;

  @include media-breakpoint-up(md) {
    padding: 68px 0 32px;
  }

  @include media-breakpoint-up(lg) {
    padding: 80px 0 110px;    
  }

  .textwidget,
  p {
    color: #002b4a;
    
    @include media-breakpoint-up(md) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  h5, .h5 {
    font-size: 15px;
    line-height: 23px;
    color: $darkblue;
    font-weight: 400;
    margin-top: 14px;

    @include media-breakpoint-up(md) {
      font-size: 16px;
      line-height: 24px;
      margin-top: 24px;
    }

    &:first-child {
      margin-top: 0;
    }
  }
  .h5 + p{
    margin-top: 0!important;
  }

  // (is_singular('ap7_blog') || is_tag() || is_post_type_archive('ap7_blog') && is_archive())
  .blogg &,
  .post-type-archive-ap7_blog &,
  .tag &,
  .category &,
  .single-ap7_blog & {
    // background: $darkblue;
    // padding: 25px 0 30px;
    // font-size: 16px;
    // line-height: 26px;
    // color: white;
    // font-weight: 700;
    @include media-breakpoint-up(lg) {
      //padding: 35px 0;
    }

    .container {
      // display: flex;
      // align-items: center;
      // justify-content: space-between;
    }
  }

  .ap7__date {
    display: block;
    color: $darkblue;
  }

  &__follow-us {
    display: flex;
    align-items: flex-start;
    // margin-top: 40px;
    // line-height: 1;

    @include media-breakpoint-up(md) {
      // margin-top: 34px;
    }

    a {
      display: inline-block;
      margin-left: 10px;
    }

    svg {
      display: block;
    }
  }
  .global-nav{
    margin-top: 24px;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

.widget {

  margin-top: 40px;

  @include make-col-ready();

  @include media-breakpoint-up(md) {
    @include make-col(6);
    margin-top: 0;
    margin-bottom: 40px;
  }

  @include media-breakpoint-up(lg) {
    @include make-col(3);
    margin-top: 0;
    margin-bottom: 0;
  }

  &:first-child {
    margin-top: 0;
  }
}

.textwidget {
  margin-top: 14px;

  @include media-breakpoint-up(md) {
    margin-top: 19px;    
  }
}

.ap7__footerSocial {
  display: flex;
  align-items: center;
  a {
    display: inline-block;
    text-decoration: none;
    margin-left: 12px;
  }

  svg {
    display: block;
  }
}