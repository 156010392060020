.ap7__blogBlock {
  &--videos {
    h2 {
      font-size: 22px;
      color: #002B4A;
      margin-bottom: 25px;
      display: none;
      
      @include media-breakpoint-up(lg) {
        font-size: 28px;
        display: block;
      }

      &.mobile-heading {
        display: block!important;
        @include media-breakpoint-up(lg) {
          display: none!important;
        }
      }
    }

    h3 {
      font-size: 18px;
      line-height: normal;
    }

    time {
      font-size: 17px;
      margin-top: 12px;
    }

    .module-content {
      display: flex;
      flex-direction: column;

      @include media-breakpoint-up(lg) {
        flex-wrap: nowrap;
        flex-direction: row;
      }
      justify-content: space-between;
    }


    .post-sidebar {
      order: 1;
      display: flex;
      flex-direction: column;
      flex-basis: 25%;
      padding-right: 17px;
      padding-left: 17px;

      @include media-breakpoint-down(md) {
        border-bottom: 1px solid #C4C4C4;
        margin-bottom: 20px;
        padding: 0;
      }
    
      .post-sidebar-content {
        margin-top: 20px;
        padding-right: 20px;

        @include media-breakpoint-up(md) {
          padding-right: 0;
        }

        .image-container {
          @include post-background(cover);
          @include aspect-ratio(60, 60);
          margin-right: 10px;
          width: 60px;
          height: 60px;
          flex: none;
          @include media-breakpoint-down(md) {
            display: none;
          }
        }
      }
      .post {
        border-bottom: 4px solid #0091f0;
        padding-bottom: 20px;
        display: block;
        margin-bottom: 20px;

        & > div:first-child {
          display: flex;
        }
      }
    
      .category-link {
        color: #0099FF;
        font-size: 13px;
        line-height: 16px;
        margin-bottom: 20px;
        display: block;
        @include media-breakpoint-up(lg) {
          font-size: 18px;
          line-height: 20px;
          margin-bottom: 0;
        }
      }
    }
    .newsletter-banner {
      order: 3;
      flex-basis: 25%;
      background: #002B4A;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 100px;
      padding-bottom: 80px;
      position: relative;

      @include media-breakpoint-down(md) {
        padding: 30px 50px;
      }

      @include media-breakpoint-up(lg) {
        padding: 0;
        padding-top: 20px;
        padding-bottom: 20px;
        margin-right: 17px;
      }
      h4 {
        @include media-breakpoint-up(lg) {
          padding-top: 0;
        }
        color: #0099FF;
        font-size: 28px;
        padding-bottom: 25px;
      }

      p {
        color: #fff;
        margin: auto;
        padding-bottom: 60px;
        max-width: 50%;

        @include media-breakpoint-up(md) {
          max-width: 80%;
          padding-bottom: 0;
        }
      }

      .newsletter-widget {
        width: 90%;
        margin: auto;
        .form-field-description{
          max-width: unset;
          font-size: 18px;
          font-style: inherit;
        }
        .yikes-mailchimp-eu-compliance-text{
          .form-field-description{
            font-size: 18px;
            font-style: inherit;
            color: #fff;
          }
        }
        button{
          &:focus{
            background:#fff!important;
            color: #0091f0!important;
          }
        }
      }

      .disclaimer {
        color: white;
        font-size: 12px;
        line-height: normal;
        display: block;

        a {
          text-decoration: underline;
        }
      }

      .yikes-easy-mc-success-message {
        background: #002B4A;
        width: 100%;
        height: 100%;
        z-index: 10;
        top: 0;
        left: 0;
        display: flex;
        padding: 0;
        max-width: 100%;
        justify-content: center;
        align-items: center;
        font-size: 28px;
        line-height: 34px;
        font-weight: 700;  
      }

      form {
        .yikes-easy-mc-submit-button {
          background: #0099FF;
          border-radius: 100px;
          color: #fff;
          font-size: 18px;
          font-weight: 600;
          border: none;
          padding: 10px 30px;
          width: 170px;
          box-sizing: border-box;
          margin: auto;
          &.disabled{
            border: 1px solid #0091f0;
            background: #002b4a;
            &:hover{
              cursor: default;
            }
          }
        }
        .failed{
          color: #d83b00;
          font-weight: 700;
          font-size: 20px;
          line-height: 30px;
          padding-top: 30px;
          &.failed-checkbox{
            padding-top: 0;
            padding-bottom: 40px;
          }
        }
        label.EMAIL-label {
          color: #FFFFFF;
          font-size: 18px;
          text-align: left;
          position: relative;

          @include media-breakpoint-up(md) {
            margin: 30px 0 50px 0;
          }

          span {
            position: absolute;
            top: 15px;
            transition: transform 150ms ease-out, font-size 150ms ease-out;

            &.focused {
              transform: translateY(-125%);
              font-size: .75em;         
            }
          }
        }

        .yikes-mailchimp-eu-compliance-label {
          line-height: 13px;
          text-align: left;
          display: flex;
          align-items: center;
          width: 80%;
          margin: auto;
          padding-bottom: 80px;
          p {
            font-size: 12px;
          }
          input[type=checkbox] {
            margin-right: 8px;
          }
          &.required{
            padding-bottom: 10px;

          }
        }

        input[type=email] {
          margin: auto;
          background-color: transparent;
          border: none;
          border-bottom: 4px solid #0099FF;
          color: #fff;
        }
      }
    }
    .featured-video-post{
      @include media-breakpoint-up(lg) {
        order: 2;
        flex-basis:50%;
      }
    }
    .featured-video {
      padding-right: 17px;
      padding-left: 17px;
      align-self: center;
      width: 100%;
      @include media-breakpoint-up(lg) {
        order: 2;
        flex-basis:50%;
      }

      @include media-breakpoint-down(md) {
        padding: 0;
      }
      h3 {
        margin-top: 20px;
      }

      time {
        margin-top: 20px;
      }

      img {
        margin:0;
        width: 100%!important;
      }

      .image-container {
        position: relative;
        @include aspect-ratio(561,316);
        @include post-background(cover);
        margin: auto;
        @include media-breakpoint-up(lg) {
          max-width: 561px;
        }

        @include media-breakpoint-down(md) {
          @include make-row();
        }

        &::after {
          content: "";
          width: 64px;
          height: 64px;
          display: inline-block;
          position: absolute;
          background: url('../images/play-button.svg');
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

      }
      h3 {
        font-size: 34px;
      }
    }
  }
}