.archive {
  article {
    &.ap7_blog {
        .post-content {
          display: flex;
          align-items: center;
          //border-top: 6px solid #0099FF;

          @include media-breakpoint-up(lg) {
            border-top: 0;
          }

        .image-container {
          @include post-background(cover);
          @include aspect-ratio(213, 203);
        }
    
          .text-content {
            p {
              margin-top: 0;
              font-size: 18px;
              line-height: 22px;
              display: none;

              @include media-breakpoint-up(lg) {
                display: block;
                @include line-clamp;
              }
            }
          }

          time {
            font-size: 17px;
            line-height: 20px;
          }
        }

        .col-lg-4 {
          padding-left:0;
          max-width: 213px;
        }
      } 
  }
  .month-pagination{
    display: flex;
    justify-content: space-between;
    a.next{
      margin-left: auto;
    }
  }
}