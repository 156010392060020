body{
    &.search-no-results{
        // search
        .wrap{
            @include media-breakpoint-up(lg) {
                min-height: 70vh;
            }
            .no-result-found{
                margin: 40px 0;
                .alert-warning{
                    margin: 40px 0 20px;
                }
            }

        }

    }
}