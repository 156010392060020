body.single {
  article.ap7_blog {
      max-width: 709px;
      time {
        margin-top: 20px;
        display: block;
      }

      .col-lg-12 {
        padding: 0;
      }

      .ap7__tags-container {
        margin-top: 40px;
        border-top: 0;
        padding-top:0;
        .ap7__tags{
          margin-top: -4px;
        }
        h2{
          flex-basis: 100%;
          font-size: 22px;
          font-weight: 300;
          line-height: 36px;
          margin-bottom: 30px;
          @include media-breakpoint-up(lg) {
            font-size: 28px;
          }
        }
        h3 {
          display: none;
        }
      }
  }

  &.single-ap7_blog {
    .img-text{
      line-height: 1;
    }
    main {

      .latest-posts-widget {
        margin-top: 40px;

        .latest-post {
          border-bottom: 4px solid #0091f0;
          padding: 20px 0 22px;

          &:last-child {
            border-bottom: 0;
          }
        }
        
        h2 {
          font-size: 22px;
          @include media-breakpoint-up(lg) {
            font-size: 28px;
          }
          line-height: 26px;
          color: #002B4A;
          font-weight: 300;
          margin-bottom: 25px;
        }

        h3 {
          font-size: 20px;
          line-height: 24px
        }

        p {
          font-size: 17px;
          line-height: 20px
        }

        hr {
          @include blog-ruler-horizontal;
          margin: 10px 0;
        }

        @include media-breakpoint-up(lg) {
          display: none;
        }
      }

      .video-container { 
        position: relative; 
        padding-bottom: 56.25%; 
        height: 0; 
        overflow: hidden; 
      }
      .video-container iframe, 
      .video-container object, 
      .video-container embed, 
      .video-container video { 
        position: absolute; 
        top: 0; 
        left: 0; 
        width: 100%; 
        height: 100%; 
      }

    }
    .author {
      margin: 12px 0 20px;

      img.avatar {
        border-radius: 50px;
        display: inline-block;
      }

      span {
          line-height: 50px;
          display: inline-block;
          font-size: 17px;
          font-weight: bold;
          vertical-align: top;
          margin-left: 8px;
      }
    }
  }
}