/* === Header === */

.ap7__blogInfo {
  margin-left: 10px;
  line-height: 22px;
  display: inline-block;
}

.ap7__headerBrand img {
  display: inline-block;
}

.ap7__blogTitle {
  font-size: 18px;
  line-height: 28px;

  @include media-breakpoint-up(lg) {
    font-size: 22px;
    line-height: 1;
  }

  display: inline-block;
}

.ap7__blogTagline {
  display: none;

  @media (min-width: 1100px) {
    display: block;
  }
}

/* === General === */

.ap7__blogBlock {
  background: #fff;
  margin-top: 40px;

  @include media-breakpoint-up(lg) {
    margin-top: 50px;
  }

  hr {
    @include blog-ruler-horizontal;
  }

  time {
    font-size: 13px;
    line-height: 16px;
    font-weight: 300;
    color: #000000;
    display: block;

    @include media-breakpoint-up(lg) {
      font-size: 17px;
      line-height: 20px;
      @include line-height-crop(1.2, 0.7);
    }
  }
}

.ap7__blogBlockPosts {
  margin-top: 32px;

  @include media-breakpoint-up(lg) {
    margin-top: 48px;
  }

  > div:first-child {
    > div {
      margin-top: 0;
    }
  }
}

.ap7__blogBlockPostsContent {
  margin-top: 50px;

  @include media-breakpoint-up(lg) {
    margin-top: 0;
  }
}

.ap7__blogBlockInnerContainer {
  @include make-container();
  @include make-container-max-widths();
  > div {
    @include media-breakpoint-up(md) {
      @include make-row();
    }
  }

  &.force-border {
    &:after {
      border-bottom: 1px solid #c4c4c4 !important;
      padding-bottom: 50px;
    }
  }

  &:after {
    content: "";
    display: block;
    width: 100%;
    border-bottom: 1px solid #c4c4c4;
    padding-bottom: 50px;

    @include media-breakpoint-down(lg) {
      padding-bottom: 0;
    }
  }

  &:last-of-type {
    &:after {
      border-bottom: 0;
    }
  }
}

body.category,
body.post-type-archive-ap7_blog {
  .content {
    h1 {
      font-size: 22px;
      line-height: 26px;
      font-weight: 300;

      @include media-breakpoint-up(lg) {
        font-size: 28px;
        line-height: 34px;
      }
    }
  }
  .ap7__headerNav {
    width: 100%;
  }
  header {
    padding-top: 0;
  }

  .wrap {
    @media (max-width: 991px) {
      padding-top: 75px;
    }
  }

  .read-more {
    font-weight: 600;
    color: #0099ff;
    margin-top: 20px;
    display: block;
    font-size: 17px;
    line-height: 20px;

    @include media-breakpoint-up(lg) {
      font-size: 22px;
      line-height: 26px;
    }
  }
}

body.tag,
body.single,
body.post-type-archive-ap7_current,
body.date,
body.page-template-ap7-blog-landing {
  header {
    padding-top: 0;
  }
}

//TODO: move to separate file

.ap7__blogAuthors {
  margin-top: 50px;

  @include media-breakpoint-up(lg) {
    margin-top: 0;
  }
}

.ap7__blogAuthorsContent {
  margin-top: 32px;

  @include media-breakpoint-up(lg) {
    margin-top: 46px;
  }
}

.ap7__archiveListContainer {
  flex-direction: column;
  margin-top: 36px;

  ul {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    margin: 0;
    padding: 0;
    text-align: center;
  }

  li {
    margin-right: 32px;
    width: calc(50% - 32px);

    @include media-breakpoint-up(lg) {
      width: auto;
    }

    a {
      position: relative;
      display: inline-block;
    }

    span {
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(125%, -4px);
      font-size: 13px;
      line-height: 20px;
      color: $blue;

      @include media-breakpoint-up(lg) {
        font-size: 14px;
        line-height: 30px;
        transform: translate(100%, -50%);
      }
    }
  }
}

.ap7__archiveIcon {
  line-height: normal;
  transition: transform 0.4s;

  &.expanded {
    transform: rotate(180deg);
  }
}

.ap7__archiveListSidebar {
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;

    @include media-breakpoint-up(lg) {
      display: block;
    }

    li {
      @include media-breakpoint-down(md) {
        width: calc(50% - 32px);
        margin-right: 32px;
        line-height: 28px;
      }

      @include media-breakpoint-down(sm) {
        line-height: 23px;
      }

      a {
        text-decoration: none;
        @include media-breakpoint-down(lg) {
          position: relative;
          display: inline-block;
        }

        span {
          color: $blue;
          &:before {
            @include media-breakpoint-up(lg) {
              content: " (";
            }
          }
          &:after {
            @include media-breakpoint-up(lg) {
              content: ")";
            }
          }

          @include media-breakpoint-down(lg) {
            position: absolute;
            top: 0;
            right: 0;
            transform: translate(125%, -4px);
            color: $blue;
          }

          @include media-breakpoint-down(sm) {
            font-size: 13px;
            line-height: 20px;
          }
        }
      }
    }
  }

  .ap7__archiveListYearBlock {
    display: block;
    margin-top: 12px;
  }
}

.ap7__tags-container {
  padding-top: 22px;
  margin-top: 50px;
  border-top: 1px solid rgba($darkblue, 0.3);

  @include media-breakpoint-up(md) {
    padding-top: 30px;
    margin-top: 76px;
  }

  a {
    @extend .ap7__button;
    font-size: 11px;
    line-height: 15px;
    padding: 5px 14px;
    margin-right: 2px;
    margin-bottom: 10px;
    &:focus-visible {
      color: #fff;
      border: 1px solid #002b4a;
      background: #002b4a !important;
    }
  }
}

.ap7__tags {
  margin-top: 15px;
}

a {
  &:focus-visible {
    outline: rgb(0, 43, 74) 1px solid;
    outline-color: inherit;
    outline-offset: 3px;
    p {
      text-decoration: underline;
    }
  }
}

.pages-a-z {
  ul {
    padding: 0;
    li.page-letter {
      display: inline;
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

@include media-breakpoint-down(md) {
  body.page-template-ap7-blog-landing .wrap,
  body.page-template-ap7-blog-landing .content {
    padding-top: 0 !important;
  }

  .ap7__blogBlock {
    margin-top: 0;
  }
}

.ap7__blogBlock--read_more {
  border-top: 0.25rem solid $blue;
  border-bottom: 0.25rem solid $blue;
  padding: 17px 0 17px;
  margin: 65px 0;

  a {
    &,
    * {
      text-decoration: none;
    }

    &:hover,
    &:focus {
      * {
        text-decoration: underline;
      }
    }
  }

  .post {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, auto);
    grid-template-areas: "image" "text";
    column-gap: 1rem;
    margin-top: 10px;

    img {
      grid-area: image;
      width: 100%;
      @include media-breakpoint-down(sm) {
        margin-bottom: 1rem;
      }
    }

    .text {
      grid-area: text;
    }

    @include media-breakpoint-up(md) {
      grid-template-columns: repeat(2, auto);
      grid-template-rows: 1fr;
      grid-template-areas: "image text";
    }
  }
}
