/**
 * Dashboard
 */

.ap7__dashboard {
  .ap7__h2--smaller {
    margin-bottom: 0;
  }

  &Date {
    font-size: 12px;
    line-height: normal;
    font-weight: 400;
    letter-spacing: initial;

    @include media-breakpoint-up(md) {
      font-weight: 600;
    }
  }
  
  &Funds {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;

    @include media-breakpoint-up(md) {
      display: flex;
      flex-direction: row;
      margin-top: 7px;
      gap: 2rem;
    }
  }

  &Fund {
    border-bottom: 1px solid rgba($darkblue, 0.23);
    @include media-breakpoint-up(md) {
      padding: initial;
      border-bottom: none;
      border-top: 1px solid rgba($darkblue, 0.23);
      &:last-child {
        border-top: none;
      }
    }
  }

  &FundValue {
    margin-top: 8px;
    vertical-align: bottom;
    display: inline-block;
    font-size: 18px;

    @include media-breakpoint-up(md) {
      font-size: 24px;
    }
  }

  &Fee {
    display: block;
    vertical-align: bottom;
    line-height: 8px;
    font-size: initial;
  }

  &FeeValue {
    font-size: 14px;
    line-height: 22px;
    font-weight: 700;

    border-radius: 14px;

    @include media-breakpoint-up(md) {
      @include small-bold-text;
    }
  }

  &FeeLabel {
    display: inline-block;
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
    letter-spacing: 0.4px;

    @include media-breakpoint-up(md) {
      line-height: 140%;
    }
  }

  &Information {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;

    @include media-breakpoint-up(md) {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  &FundTitle {
    @include small-bold-text;
    line-height: 120%;
  }

  &Title {
    margin-bottom: 1rem;
  }

  &FundStat {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  &Table {
    margin-top: 30px;
    border-spacing: 0;

    @include media-breakpoint-up(md) {
      margin-top: 40px;
    }

    &Container {
      margin-top: 48px;

      @include media-breakpoint-up(md) {
        margin-top: 64px;
      }

      @include media-breakpoint-up(lg) {
        margin-top: 0;
      }
    }

    td,
    th {
      padding: 0 10px;
      text-align: center;
      border-left: 1px solid rgba($darkblue, 0.23);

      @include media-breakpoint-up(md) {
        padding: 0 1rem;
      }

      &:first-child {
        font-size: 12px;
        line-height: 14px;
        font-weight: 700;
        padding-left: 0;
        border-left: none;

        @include media-breakpoint-up(md) {
          padding: 0 12px;
          font-size: 20px;
          line-height: 24px;
          border-left: 1px solid rgba($darkblue, 0.23);
        }
      }

      &:last-child {
        @include media-breakpoint-up(md) {
          border-right: 1px solid rgba($darkblue, 0.23);
        }
      }
    }

    td {
      font-size: 20px;
      font-weight: 300;
      padding-top: 10px;
      padding-bottom: 10px;

      @include media-breakpoint-up(md) {
        font-size: 34px;
        line-height: 48px;
      }

      &:nth-child(2n) {
        color: $blue;
      }
    }

    th {
      padding: 10px 20px;
      font-size: 18px;
      line-height: 22px;
      font-weight: 700;

      @include media-breakpoint-up(md) {
        padding-bottom: 18px;
        font-size: 24px;
        line-height: 120%;
        letter-spacing: -0.5px;
      }
    }
  }

  &Details {
    display: block;
    summary {
      padding: 0.5rem 0;
      width: fit-content;
      cursor: pointer;

      &::after {
        content: "+";
        display: inline-block;
        margin-left: 8px;
      }

      &::-webkit-details-marker {
        display:none;
      }
    }

    &[open] summary::after {
      content: "-";
    }
    @include media-breakpoint-up(md) {
      display: none;
    }

    .ap7__expandedFootnote {
      margin-bottom: 17px;
    }
  }

  &Footnote {
    margin-top: 23px;
    display: block;
  }

  &DesktopFootnote {
    display: none;
    font-size: 12px;

    @include media-breakpoint-up(md) {
      display: block;
    }
  }

  .ap7__thumbnailContainer {
    margin-top: 24px;
    margin-bottom: 17px;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }

    a.thumbnail_wrapper:focus {
      outline: 1px solid #002b4a;
      outline-color: inherit;
      outline-offset: 3px;
      padding-top: 178px;
      padding-right: 58px;
    }

    .thumbnail {
      width: 300px;
      height: 170px;
      background-size: cover;
      background-position: 50% 50%;
      display: block;
      position: relative;
      // transition: .5s;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
      }

      .overlay {
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        left: 0;
        background-color: #000;
        opacity: 0.1;
        transition: 0.5s opacity;
      }

      &:hover .overlay {
        opacity: 0.2;
      }
    }

    .ap7__textSmall {
      max-width: 300px;
      display: inline-block;
      margin-top: 5px;
    }
  }
}
