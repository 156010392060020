// Grid system
.main {
  // @include make-col-ready();
  // @include media-breakpoint-up(sm) {
  //   @include make-col($main-sm-columns);
  //   .sidebar-primary & {
  //     @include make-col($main-sm-columns - $sidebar-sm-columns);
  //   }
  // }

  .single-ap7_blog &,
  .archive.category &,
  .archive.date &,
  .arhcive.post-type-archive-ap7_blog  {
    order: 2;

    @include media-breakpoint-up(lg) {
      order: 1;
    }
  }

  .single &,
  .category &,
  .post-type-archive-ap7_blog &,
  .date &,
  .tag &,
  .search &,
  .page:not(.home):not(.page-template-ap7-blog-landing):not(.page-template-template-category-page):not(.page-template-template-lottiepage):not(.page-template-template-tankonomi):not(.page-template-template-listning-med-puffar):not(.page-template-template-jubileum) &,
  .post-type-archive-ap7_current &,
  .post-type-archive-ap7_blog & {
    @include make-col-ready();

    @include media-breakpoint-up(lg) {
      @include make-col($main-sm-columns);
    }
  }

}

.sidebar {
  @include make-col-ready();
  order: 1;
  @include media-breakpoint-up(lg) {
    @include make-col($sidebar-sm-columns);
    order: 0;
  }

  .single-ap7_blog & {
    order: 1;
    @include media-breakpoint-up(lg) {
      @include make-col($blog-sidebar-columns);
      order: 2;
    }
  }

  .archive.post-type-archive-ap7_blog:not(.date) & {
    display: none;
  }
}

.wrap {
  .single &,
  .category &,
  .date &,
  .tag &,
  .search &,
  .page:not(.home):not(.page-template-template-lottiepage):not(.page-template-template-tankonomi):not(.page-template-template-listning-med-puffar):not(.page-template-template-jubileum) &,
  .post-type-archive-ap7_current &,
  .post-type-archive-ap7_blog & {
    @include make-container();
    @include make-container-max-widths();
  }

  body:not(.home):not(.page-template-template-lottiepage) & {
    padding-top: 84px;
    @media (max-width: 991px) {
      padding-top: 65px!important;

    }
  }
}

.tank-part{
  @include make-container();
  @include make-container-max-widths();
  padding: 0 17px;
}

.tank-blog{
  margin-top: 120px;
}

.content {
  outline: none;

  .page:not(.home):not(.page-template-template-lottiepage) &{

    @include make-row();
    padding-top: 24px;
    padding-bottom: 54px;
    display: flex;
    @include media-breakpoint-up(lg) {
      padding-top: 80px;
      padding-bottom: 120px;
    }
    @media (max-width: 991px) {
      padding-top: 65px;
    }
  }


  .blogg &,
  .post-type-archive-ap7_current &
 {
    @include make-row();
    padding-top: 24px;
    padding-bottom: 54px;
    display: flex;
    @include media-breakpoint-up(lg) {
      padding-top: 86px!important;
      padding-bottom: 120px;
    }
    @media (max-width: 991px) {
      padding-top: 0px;
    }
  }

  .tag &,
  .category &,
  .single &,
  .date &,
  .search &,
  .post-type-archive-ap7_blog &
  {
    @include make-row();
    padding-top: 24px;
    padding-bottom: 54px;
    display: flex;
    @include media-breakpoint-up(lg) {
      padding-top: 150px!important;
      padding-bottom: 120px;
    }
    @media (max-width: 991px) {
      padding-top: 24px;
    }
  }

  // .category &{
  //   @include make-row();
  //   padding-top: 24px;
  //   padding-bottom: 54px;
  //   display: flex;
  //   @include media-breakpoint-up(lg) {
  //     padding-top: 150px!important;
  //     padding-bottom: 120px;
  //   }
  //   @media (max-width: 991px) {
  //     padding-top: 24px;
  //   }
  // }



}
body.page #contentMain{
  @include media-breakpoint-up(lg) {
    //padding-top: 86px!important;
  }
}
#contentMain{
  transition: 1s all ease;
  &.extended{
    padding-bottom: 500px;
    transition: 1s all ease;
  }
  &.extended-more{
    padding-bottom: 1000px;
    transition: 1s all ease;
  }
}
body.single-ap7_blog{
  .wrap{
    padding-top: 65px!important;
  }
}

body.page-template-ap7-blog-landing{
  .wrap, .main, .ap7__blogBlock--featured{
    // padding-top: 0;
    // margin-top: 0;
  }
}
