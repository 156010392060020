.ap7__button {
  display: inline-block;
  padding: 10px 20px 10px;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0;
  font-weight: 700;
  text-transform: uppercase;
  color: $darkblue;
  border: 1px solid $darkblue;
  text-decoration: none;
  border-radius: 22px;
  background: none;
  appearance: none;

  &:focus {
    outline: 0;
  }

  &[disabled] {
    opacity: 0.25;
  }

  @include media-breakpoint-up(md) {
    font-size: 14px;
    line-height: 22px;
    padding: 10px 22px 11px;
  }
}

.ap7__searchInput {
  width: 100%;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0;
  padding: 8px 20px 8px;
  border-radius: 6px;
  border: 1px solid #002b4a;
  @include media-breakpoint-up(md) {
    width: 33%;
    padding: 10px 22px 11px;
  }
}